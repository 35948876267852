import MomentUtils from '@date-io/moment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core';
import 'moment/locale/pt-br';
import EventIcon from '@material-ui/icons/Event';

moment.locale('pt-br');

const CustomDatePicker = ({
  value,
  onChange,
  label,
  minDate,
  disabled,
  disableCalendarIcon,
  error,
  helperText,
  placeholder,
  height,
  maxDate
}) => {
  const colorTheme = createTheme({
    palette: {
      primary: { 500: '#A0001F' }, // custom color in hex
    },
    overrides: {
      MuiInputBase: {
        input: {
          height,
        },
      },
    },
  });

  return (
    <MuiThemeProvider theme={colorTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
        <KeyboardDatePicker
          inputProps={{ inputMode: 'numeric' }}
          disabled={disabled}
          variant="inline"
          fullWidth
          error={error}
          helperText={helperText}
          clearable
          inputVariant="outlined"
          label={label}
          onChange={onChange}
          InputAdornmentProps={{ position: 'end' }}
          autoOk
          minDateMessage="A data não pode ser anterior a data atual para esse tipo de pagamento"
          invalidDateMessage="A data não é válida"
          minDate={minDate}
          maxDateMessage="A data não pode ser anterior a data atual para esse tipo de pagamento"
          maxDate={maxDate}
          value={value || null}
          format="DD/MM/YYYY"
          keyboardIcon={disableCalendarIcon ? <EventIcon /> : <EventIcon />}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder={placeholder}
          height={height}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

export default CustomDatePicker;
