import {
  makeStyles,
  createStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

export const styles = {
  titleStyle: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '-1px',
    marginBottom: 15,
    fontFamily: 'DM Sans',
  },
  buttonStyle: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',

    backgroundColor: '#A0001F',
    color: '#ffffff',

    borderRadius: '3.188rem',
    border: '1ps solid #A0001F',
    boxSizing: 'border-box',

    padding: '6.5px 12px',
    width: '10.563rem',
    height: '2.5rem',

    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.25)',

    marginTop: '9px',
  },
  primaryColor: {
    color: '#A0001F',
  },
  buttonPadding: {
    padding: 10,
    marginTop: '-12px',
  },
  loadingStyle: {
    color: '#A0001F',
    display: 'flex',
    height: '30vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTextStyle: {
    textTransform: 'none',
    fontWeight: 500,
  },
  buttonBodyTextStyle: {
    fontWeight: 'bold',
    lineHeight: '14px',
    fontFamily: 'DM Sans',
  },
  contentStyle: {
    height: '65vh',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  logoStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  biggerTextStyle: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-0.5px',
    marginTop: '10px',
    marginBottom: '10px',
    fontFamily: 'DM Sans',
  },
  smallerTextStyle: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    fontFamily: 'DM Sans',
    marginBottom: '20px',
  },
};

export default styles;

export const useStyles = makeStyles(theme =>
  createStyles({
    menuList: {
      marginTop: '48px',
      marginLeft: '-30px',
      wordBreak: 'break-all',
    },
    table: {
      maxWidth: '100%',
    },
    statusCell: {
      padding: '10px',
      alignItems: 'center',
    },
    searchBackground: {
      backgroundColor: '#F1F1F1',
      marginBottom: 15,
      borderRadius: 4,
    },
    inputSearch: {
      marginBottom: '15px',
      border: 'none',
    },
    buttonBodyTextStyleClasses: {
      padding: '12px',
      textTransform: 'none',
      width: '25%',
      color: '#A0001F',
      borderColor: '#c1c1c1',
      '&:hover': {
        borderColor: '#A0001F',
        backgroundColor: 'transparent',
      },
    },
    tableRow: {
      height: 69,
    },
  }),
);

export const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(1.5),
  },
}));

export const theme = createMuiTheme(
  {
    overrides: {
      MuiTableRow: {
        root: {
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        },
      },
      MuiTableCell: {
        root: {
          fontFamily: 'DM Sans',
          fontSize: '15px',
          padding: '10px',
        },
      },
      MuiTableContainer: {
        root: {
          marginTop: '15px',
          maxWidth: 'window.innerWidth',
        },
      },
      MuiPaper: {
        elevation8: {
          boxShadow: ' 0px 3px 2px 1px rgba(0,0,0,0.01) ',
        },
      },
    },
  },
  ptBR,
);
