import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import {
  Button,
  Dialog,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Snackbar,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { api } from '../../../../../services/api';
import { ReactComponent as LinkNumberCodeBarIcon } from '../../../../../assets/LinkNumberCodeBarIcon.svg';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function ModalRemoveAccount({ open, onClose }) {
  const [rows, setRows] = useState([]);
  const [copied, setCopied] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const closeModal = () => {
    onClose();
  };

  useEffect(() => {
    api.get('/cashIn/teds/somapayAccounts').then(data => setRows(data));
  }, [open]);
  const onCopy = () => {
    setCopied(true);
  };
  const handleClick = () => {
    setOpenSnackbar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <div className={styles.dialog}>
        <div>
          <Typography className={styles.biggerText}>Dados para TED</Typography>
          <Typography className={styles.smallText}>
            Utilize estes dados para realizar uma transferência TED para sua
            conta FortesPay
          </Typography>
        </div>
        <div className={styles.table}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography className={styles.tableTitle}>Banco</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className={styles.tableTitle}>
                      Agência
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className={styles.tableTitle}>Conta</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className={styles.tableTitle}>
                      Titular
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className={styles.tableTitle}>CNPJ</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {open &&
                  rows.data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {row.bankName}
                      </TableCell>
                      <TableCell align="left">
                        <div className={styles.tableCellArea}>
                          <Typography>{row.agency}</Typography>
                          <Tooltip
                            title="Copiar número da agência"
                            className={styles.copyButton}
                          >
                            <CopyToClipboard
                              text={row.agency}
                              onCopy={() => onCopy}
                            >
                              <button
                                onClick={handleClick}
                                className={styles.copyButton}
                              >
                                <LinkNumberCodeBarIcon />
                              </button>
                            </CopyToClipboard>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className={styles.tableCellArea}>
                          <Typography>{row.account}</Typography>
                          <Tooltip
                            title="Copiar número da conta"
                            className={styles.copyButton}
                          >
                            <CopyToClipboard
                              text={row.account}
                              onCopy={() => onCopy}
                            >
                              <button
                                onClick={handleClick}
                                className={styles.copyButton}
                              >
                                <LinkNumberCodeBarIcon />
                              </button>
                            </CopyToClipboard>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className={styles.tableCellArea}>
                          <Typography>{row.name}</Typography>
                          <Tooltip
                            title="Copiar nome do titular"
                            className={styles.copyButton}
                          >
                            <CopyToClipboard
                              text={row.name}
                              onCopy={() => onCopy}
                            >
                              <button
                                onClick={handleClick}
                                className={styles.copyButton}
                              >
                                <LinkNumberCodeBarIcon />
                              </button>
                            </CopyToClipboard>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className={styles.tableCellArea}>
                          <Typography>{row.cnpj}</Typography>
                          <Tooltip
                            title="Copiar CNPJ"
                            className={styles.copyButton}
                          >
                            <CopyToClipboard
                              text={row.cnpj}
                              onCopy={() => onCopy}
                            >
                              <button
                                onClick={handleClick}
                                className={styles.copyButton}
                              >
                                <LinkNumberCodeBarIcon />
                              </button>
                            </CopyToClipboard>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className={styles.buttonsGroup}>
          <Button className={styles.confirmButton} onClick={closeModal}>
            Fechar
          </Button>
        </div>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
        message="Item copiado com sucesso"
        action={
          <>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
      ;
    </Dialog>
  );
}

export default ModalRemoveAccount;
