import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { ReactComponent as FileError } from '../../../../../assets/fileError.svg';
import { ReactComponent as NotFound } from '../../../../../assets/not-found.svg';
import { styles } from '../../../../Payroll/PayrollDetails/styles';

export default ({ type, title, text }) => {
  const history = useHistory();
  const reload = () => {
    history.go(0);
  };
  return (
    <Grid container style={styles.gridStyle}>
      {type === 'EMPLOYEES_NOT_FOUND' ? <NotFound /> : <FileError />}
      <Typography style={styles.titleStyle}>
        {title || 'Oops! Não conseguimos exibir as informações.'}
      </Typography>
      <Typography style={styles.thinTextStyle}>
        {text ||
          'Em caso de dúvidas, entre em contato com nossa Central de Relacionamento.'}
      </Typography>
      {!type && (
        <Button
          variant="outlined"
          color="#A0001F"
          style={styles.backButton}
          onClick={reload}
        >
          Recarregar
        </Button>
      )}
    </Grid>
  );
};
