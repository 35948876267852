import React from 'react';

import { CircularProgress } from '@material-ui/core';

import styles from './styles.module.css';

function LogoLoading() {
  return (
    <div className={styles.loadingContainer}>
      <CircularProgress className={styles.loader} />
    </div>
  );
}

export default LogoLoading;
