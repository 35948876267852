import React, { useCallback, useEffect, useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Form from '../../../../../components/Form';
import Buttons from '../../../../../components/Buttons';

import {
  useConfirmAuthorizationMutation,
  usePreAuthorizePayrollMutation,
} from '../../../../../redux/store/Payroll/payroll.api';
import { PayrollFileUploadActions } from '../../../../../redux/store/Payroll/PayrollFiles';

import useGeolocation from '../../../../../hooks/useGeolocation';
import { useCountdown } from '../../../../../hooks/useValidationForUnformattedDate/useCountdown';
import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack';
import { handleRequestError } from '../../../../../utils/handleRequestError';

export const PayrollAuthorizationModal = NiceModal.create(
  ({ authData = { status: 'EMAIL_SENT' } }) => {
    const COUNTDOWN_TIME = 60;

    const dispatch = useDispatch();
    const modal = useModal();
    const history = useHistory();
    const location = useGeolocation();

    const [handlePreAuthorizePayroll] = usePreAuthorizePayrollMutation();

    const [
      handleConfirmAuthorization,
      { isLoading },
    ] = useConfirmAuthorizationMutation();

    const { code: companyCode } = useSelector(
      state => state.companies.currentCompany,
    );

    const { nsuListToAuthorize } = useSelector(
      state => state.payroll.payrollFilesUpload,
    );

    const [data, setData] = useState(authData);
    const [isCountdownStarted, setIsCountdownStarted] = useState(false);

    const { currentTime } = useCountdown(COUNTDOWN_TIME, isCountdownStarted);

    const { control, handleSubmit } = useForm({
      defaultValues: { pin: '', expressPayment: false },
    });

    const handleStartTimer = () => {
      setIsCountdownStarted(true);

      setTimeout(() => {
        setIsCountdownStarted(false);
      }, COUNTDOWN_TIME * 1000);
    };

    const onPreAuthorizePayroll = useCallback(() => {
      handleStartTimer();

      handlePreAuthorizePayroll({
        emailNotReceived: true,
        nsuList: nsuListToAuthorize?.map(nsu => Number(nsu)),
      })
        .unwrap()
        .then(res => {
          const { requestId, validationType: status, email, phoneNumber } = res;
          setData({ requestId, status, email, phoneNumber });
        })
        .catch(errors => {
          handleRequestError(
            errors,
            'Houve um erro pontual ao pré-autorizar. Tente novamente mais tarde.',
          );
        });
    }, [nsuListToAuthorize]);

    const onConfirmAuthorization = useCallback(
      ({ pin, expressPayment }) => {
        handleConfirmAuthorization({
          requestId: data?.requestId,
          pin,
          expressPayment,
          companyCode,
          nsuList: nsuListToAuthorize,
          ...location,
        })
          .unwrap()
          .then(() => {
            modal.hide();

            handleSnackbarStack().success('Pagamentos enviados com sucesso!');

            history.push('/payroll');

            dispatch(PayrollFileUploadActions.removeAllFiles());
          })
          .catch(errors => {
            handleRequestError(
              errors,
              'Houve um erro pontual ao enviar os pagamentos. Tente novamente mais tarde.',
            );
          });
      },
      [companyCode, nsuListToAuthorize, location],
    );

    useEffect(() => {
      handleStartTimer();
    }, []);

    return (
      <Dialog
        maxWidth="xs"
        open={modal.visible}
        onClose={modal.remove}
        PaperProps={{ style: { padding: '32px' } }}
      >
        <DialogTitle style={{ padding: 0 }}>
          Autorizar este pagamento?
        </DialogTitle>
        <DialogContent style={{ marginTop: '12px', padding: 0 }}>
          <Form.FormBase onSubmit={() => handleSubmit(onConfirmAuthorization)}>
            <DialogContentText
              style={{ fontSize: '1rem', marginBottom: '24px' }}
            >
              Ao autorizar, o pagamento desta importação será processado e não
              poderá ser desfeito.
            </DialogContentText>
            <DialogContentText
              style={{
                fontSize: '1rem',
                textAlign: 'center',
                color: '#000',
              }}
            >
              {data.status === 'EMAIL_SENT'
                ? 'Digite o código enviado para seu e-mail.'
                : 'Digite o código enviado por SMS.'}
            </DialogContentText>

            <DialogContentText style={{ color: '#666', textAlign: 'center' }}>
              {data.status === 'EMAIL_SENT'
                ? data.email ?? `-`
                : data.phoneNumber ?? `-`}
            </DialogContentText>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '1rem',
                }}
              >
                <Controller
                  control={control}
                  name="pin"
                  render={({ field }) => (
                    <Form.NumericField
                      precision={6}
                      size="medium"
                      label="Código de autorização"
                      helperText={
                        data.status === 'EMAIL_SENT'
                          ? 'Não recebeu o e-mail? Verifique as pastas Spam e Lixeira.'
                          : 'Não recebeu o SMS? Tente realizar novamente a autorização.'
                      }
                      {...field}
                    />
                  )}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '1rem',
                }}
              >
                <Buttons.SecondaryButton
                  color="primary"
                  disabled={isCountdownStarted}
                  title={
                    isCountdownStarted
                      ? `Enviar código por SMS em: ${currentTime}`
                      : 'Enviar código por SMS'
                  }
                  onClick={onPreAuthorizePayroll}
                />
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Controller
                  control={control}
                  name="expressPayment"
                  render={({ field }) => (
                    <Form.Checkbox
                      label="Autorizar pagamento com liberação imediata"
                      color="secondary"
                      checked={field.value}
                      {...field}
                    />
                  )}
                />
              </Box>
              <DialogActions style={{ padding: 0, gap: '16px' }}>
                <Buttons.SecondaryButton
                  size="large"
                  color="primary"
                  title="Cancelar"
                  onClick={modal.remove}
                />

                <Buttons.PrimaryButton
                  size="large"
                  type="submit"
                  color="secondary"
                  title="Autorizar pagamento"
                  loading={isLoading}
                />
              </DialogActions>
            </Box>
          </Form.FormBase>
        </DialogContent>
      </Dialog>
    );
  },
);
