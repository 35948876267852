import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { api } from '../../../services/api';

const initialState = {
  isLoading: false,
  isSending: false,
  isFetchingUsers: false,
  users: [],
  formState: {},
  selectedUser: {},
  authLink: '',
  filters: {
    nameOrCpf: '',
  },
  pagination: {
    page: 0,
    perPage: 5,
    length: 0,
  },
  error: {
    hasError: false,
    message: '',
  },
};

const getUsersList = createAsyncThunk(
  'userManagement/getUsersList',
  async (_, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;
    const { filters } = getState().userManagement;

    const params = {
      companyCode: currentCompany.code,
      ...filters,
    };

    return api
      .get('/users/platform', { params })
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const getFaceAuth = createAsyncThunk(
  'userManagement/getFaceAuth',
  async (_, { rejectWithValue }) => {
    return api
      .get('users/platform/facial-recognition')
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const createUser = createAsyncThunk(
  'userManagement/createUser',
  async (_, { getState, rejectWithValue }) => {
    const { currentCompany } = getState().companies;

    const {
      formState: { role, ...form },
    } = getState().userManagement;

    const body = {
      ...form,
      companyCode: currentCompany.code,
      roles: [role],
    };

    return api
      .post('/users/platform/create', body)
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const setUserSituation = createAsyncThunk(
  'userManagement/setUserSituation',
  async (_, { rejectWithValue, getState }) => {
    const { currentCompany } = getState().companies;
    const { situation, cpf } = getState().userManagement.selectedUser;

    return api
      .put(`users/${cpf}/${currentCompany.code}`, { situation })
      .then(({ data }) => ({ data }))
      .catch(error =>
        rejectWithValue({
          error: error.response.data.errors[0].errorDetail,
        }),
      );
  },
);

const UserManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      state.filters = { ...state.filters, ...payload };
    },
    changePage: (state, { payload }) => {
      state.pagination = {
        page: payload.page ?? state.pagination.page,
        perPage: payload.perPage ?? state.pagination.perPage,
        length: payload.length ?? state.pagination.length,
      };
    },
    setFormState: (state, { payload }) => {
      state.formState = payload;
    },
    setSelectedUser: (state, { payload }) => {
      state.selectedUser = payload;
    },
  },
  extraReducers: {
    [getUsersList.pending]: state => {
      state.isFetchingUsers = true;
      state.users = [];
    },
    [getUsersList.fulfilled]: (state, { payload }) => {
      state.isFetchingUsers = false;
      state.users = payload.data;
      state.pagination = {
        ...state.pagination,
        length: payload.data.totalSize,
      };
    },
    [getUsersList.rejected]: (state, { payload }) => {
      state.isFetchingUsers = false;
      state.error = {
        hasError: true,
        message: payload.message,
      };
    },
    [getFaceAuth.pending]: state => {
      state.isLoading = true;
    },
    [getFaceAuth.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.authLink = payload.data;
    },
    [getFaceAuth.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message: payload.message,
      };
    },
    [createUser.pending]: state => {
      state.isLoading = true;
    },
    [createUser.fulfilled]: state => {
      state.isLoading = false;
      state.formState = initialState.formState;
    },
    [createUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = {
        hasError: true,
        message:
          payload?.error ||
          'Ocorreu um erro ao cadastrar o usuário. Por favor, tente novamente em instantes.',
      };
    },
    [setUserSituation.pending]: state => {
      state.isSending = true;
    },
    [setUserSituation.fulfilled]: state => {
      state.isSending = false;
    },
    [setUserSituation.rejected]: (state, { payload }) => {
      state.isSending = false;
      state.error = {
        hasError: true,
        message:
          payload?.error ||
          'Ocorreu um erro ao cadastrar o usuário. Por favor, tente novamente em instantes.',
      };
    },
  },
});

const userManagementReducer = UserManagementSlice.reducer;
const UserManagementActions = {
  ...UserManagementSlice.actions,
  getUsersList,
  createUser,
  getFaceAuth,
  setUserSituation,
};

export { UserManagementActions, userManagementReducer };
