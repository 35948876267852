import React, { useState } from 'react';
import Carousel from 'nuka-carousel';
import { useHistory } from 'react-router-dom';

import Card from './Card';

import styles from './styles.module.css';

import { isMobile } from '../../utils/breakpoints';
import { handleSnackbarStack } from '../../utils/handleSnackbarStack';
import { api } from '../../services/api';

import { ReactComponent as ArrowRight } from '../../assets/arrow-right-purple.svg';
import { ReactComponent as ArrowLeft } from '../../assets/arrow-left-purple.svg';
import { ReactComponent as WelcomeSliderIcon1 } from '../../assets/welcome-carousel-1.svg';
import { ReactComponent as WelcomeSliderIcon2 } from '../../assets/welcome-carousel-2.svg';
import { ReactComponent as WelcomeSliderIcon3 } from '../../assets/welcome-carousel-3.svg';
import { ReactComponent as WelcomeSliderIcon4 } from '../../assets/welcome-carousel-4.svg';
import { ReactComponent as WelcomeSliderIcon5 } from '../../assets/welcome-carousel-5.svg';

export default function WelcomeCarousel() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const goToHomePage = () => {
    setLoading(true);

    api
      .put('/auth/firstAccess', { firstAccess: false })
      .then(() => history.push('/'))
      .catch(err => {
        handleSnackbarStack().error(err);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <Carousel
          className={styles.carousel}
          disableEdgeSwiping
          navButtonsAlwaysVisible
          animation="fade"
          autoPlay={false}
          defaultControlsConfig={{
            nextButtonText: <ArrowRight className={styles.arrow} />,
            prevButtonText: <ArrowLeft className={styles.arrow} />,
            pagingDotsStyle: {
              fill: '#A0001F',
              outline: 'none',
              marginBottom: isMobile ? '30px' : '16px',
            },
            prevButtonStyle: {
              background: 'transparent',
              marginTop: isMobile ? '410px' : '390px',
              marginLeft: '50px',
              outline: 'none',
            },
            nextButtonStyle: {
              background: 'transparent',
              marginTop: isMobile ? '410px' : '390px',
              marginRight: '50px',
              outline: 'none',
            },
          }}
        >
          <Card
            icon={<WelcomeSliderIcon1 />}
            title="Bem-vindo à nova Plataforma FortesPay Beta"
            description=" Um novo design e novas funcionalidades para facilitar cada vez mais a sua rotina."
          />
          <Card
            icon={<WelcomeSliderIcon2 />}
            title="Novo Painel de Controle"
            description="Redesenhamos um novo painel com atalhos para as principais funcionalidades."
          />
          <Card
            icon={<WelcomeSliderIcon3 />}
            title="Tarefas aprimoradas"
            description="Melhoramos os caminhos para cadastro de funcionários e para fluxos de pagamento."
          />
          <Card
            icon={<WelcomeSliderIcon4 />}
            title="Simples e prático"
            description="Acompanhar o progresso das movimentações está mais fácil."
          />
          <Card
            icon={<WelcomeSliderIcon5 />}
            title="Experiência Beta"
            description="Contribua com feedbacks para deixar a plataforma ainda melhor! Queremos te ouvir."
            lastCard
            onClick={goToHomePage}
            loading={loading}
          />
        </Carousel>
      </div>
    </div>
  );
}
