import {
  makeStyles,
  createStyles,
  createMuiTheme,
} from '@material-ui/core/styles';

export const styles = {
  titleStyle: {
    fontSize: 34,
    lineHeight: '36px',
    fontFamily: 'DM Sans',
    marginBottom: 10,
    fontWeight: 700,
  },
  subtitleStyle: {
    fontWeight: 500,
    fontSize: 20,
  },
  titleStyleError: {
    color: '#A0001F',
    fontFamily: 'DM Sans',
    fontWeight: 540,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    marginTop: '-30',
    marginBottom: '20px',
    borderRadius: '10px',
    backgroundColor: 'bisque',
    padding: '10px',
    paddingLeft: 'center',
    textAlign: 'center',
  },

  currencyError: {
    color: '#A0001F',
    fontFamily: 'DM Sans',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '14px',
    letterSpacing: '0.5px',
    marginTop: '10px',
  },

  divider: {
    display: 'flex',
    marginTop: '30px',
    marginLeft: '-22px',
    marginRight: '-22px',
    wordBreak: 'break-all',
  },
  contentStyle: {
    height: '63vh',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridStyle: {
    display: 'flex',
    justifyContent: 'spaceBetween',
  },
  gridItem: {
    marginRight: '30px',
    width: '30vw',
  },
  mobileContentStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  biggerTextTitleStyle: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '-0.5px',
    fontFamily: 'DM Sans',
  },
  biggerTextStyle: {
    fontSize: 18,
    fontWeight: 500,
    letterSpacing: '-0.5px',
    fontFamily: 'DM Sans',
    marginBottom: '10px',
  },
  marginStyle: {
    marginTop: '2em',
  },
  description: {
    fontFamily: 'DM Sans',
    letterSpacing: '-0.5px',
  },
  input: {
    color: '#FF0000',
    '&$focused': {
      borderColor: '#A0001F',
    },
  },
};

export const pageStyle = makeStyles(theme =>
  createStyles({
    typographyStyle: {
      fontFamily: 'DM Sans',
      fontSize: '1.1em',
      marginTop: '20px',
      display: 'flex',
    },
    descriptionArea: {
      marginTop: '4%',
    },
  }),
);

export const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          color: '#A0001F',
        },
        '&:hover $notchedOutline': {
          borderColor: '#A0001F',
        },
        '&$focused $notchedOutline': {
          borderColor: '#A0001F',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#A0001F',
        },
      },
    },
  },
});
