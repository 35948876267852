import {
  makeStyles,
  createStyles,
  createTheme,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

export const style = {
  menuList: {
    marginTop: '48px',
    marginLeft: '-30px',
    wordBreak: 'break-all',
  },
  titleStyle: {
    fontSize: 36,
    fontWeight: 700,
    lineHeight: '36px',
    letterSpacing: '-1px',
    marginBottom: 40,
    color: '#ffffff',
    fontFamily: 'DM Sans',
  },
  buttonStyle: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    backgroundColor: '#A0001F',
    color: '#ffffff',
    borderRadius: '3.188rem',
    border: '1ps solid #A0001F',
    boxSizing: 'border-box',
    padding: '6.5px 12px',
    width: '10.563rem',
    height: '2.5rem',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.25)',

    marginTop: '9px',
  },
  primaryColor: {
    color: '#A0001F',
  },
  buttonPadding: {
    padding: 10,
    marginTop: '-12px',
  },
  loadingStyle: {
    color: '#A0001F',
    display: 'flex',
    height: '30vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTextStyle: {
    textTransform: 'none',
    fontWeight: 500,
  },
  buttonBodyTextStyle: {
    fontWeight: 'bold',
    lineHeight: '14px',
    fontFamily: 'DM Sans',
  },
  contentStyle: {
    height: '65vh',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  logoStyle: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },

  biggerTextStyle: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-0.5px',
    marginTop: '10px',
    marginBottom: '10px',
    fontFamily: 'DM Sans',
  },
  smallerTextStyle: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    fontFamily: 'DM Sans',
    marginBottom: '20px',
  },
  circularLoading: {
    marginTop: '10%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
};

export default style;

export const useStyles = makeStyles(theme =>
  createStyles({
    menuList: {
      marginTop: '48px',
      marginLeft: '-30px',
      wordBreak: 'break-all',
    },
    table: {
      maxWidth: '100%',
      background: '#ffffff',
      boxShadow: ' 0px 3px 2px 1px rgba(0,0,0,0.01)',
      border: '1px solid rgba(0,0,0,0.01)',
      overflow: 'hidden',
    },
    tableContainer: {
      marginBottom: 60,
    },
    statusCell: {
      padding: '25px',
    },
    searchBackground: {
      backgroundColor: '#F1F1F1',
      marginBottom: 15,
      borderRadius: 4,
    },
    inputSearch: {
      marginBottom: '15px',
      border: 'none',
    },
    buttonBodyTextStyleClasses: {
      padding: '12px',
      textTransform: 'none',
      width: '25%',
      color: '#A0001F',
      borderColor: '#c1c1c1',
      '&:hover': {
        borderColor: '#A0001F',
        backgroundColor: 'transparent',
      },
    },
    checkIcon: {
      color: '#A0001F',

      '&:disabled': {
        color: '#A0001F4D',
      },
    },
    customTooltip: {
      '& after': {
        content: '',
        width: 0,
        height: 0,
        position: 'absolute',
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderTop: '20px solid #168243',
        bottom: '-20px',
        right: '20%',
      },
    },
  }),
);

export const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(1.5),
  },
}));

export const useDetailsStyles = makeStyles(theme => ({
  root: {
    padding: '16px 24px',
    maxWidth: '390px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  drawerTitle: {
    fontSize: '18px',
    fontWeight: 700,
    marginBottom: '8px',
  },
  drawerSubtitle: {
    fontSize: '16px',
    color: theme.palette.grey[700],
    marginBottom: '24px',
  },
  infoContainer: {
    margin: '24px 0',
    padding: '16px',
    backgroundColor: theme.palette.grey[100],
    gap: '16px 0',
  },
  infoGridItems: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  infoBody: {
    fontSize: '16px',
    color: theme.palette.grey[500],
  },
  infoValue: {
    fontWeight: 500,
    textAlign: 'right',
  },
  sectionContainer: {
    gap: '16px 0',
  },
  sectionHeader: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '8px',
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: '700',
  },
  divider: {
    margin: '24px 0',
  },
  operationIcon: {
    marginRight: '4px',
  },
  errorReason: {
    fontWeight: 500,
    fontSize: '1rem',
  },
}));

export const theme = createTheme(
  {
    overrides: {
      MuiTableRow: {
        root: {
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        },
      },
      MuiTableCell: {
        root: {
          fontFamily: 'DM Sans',
          fontSize: '15px',
          padding: '10px',
        },
      },
      MuiTableContainer: {
        root: {
          marginTop: '15px',
          maxWidth: 'window.innerWidth',
        },
      },
      MuiPaper: {
        elevation8: {
          boxShadow: ' 0px 3px 2px 1px rgba(0,0,0,0.01) ',
        },
      },
    },
  },
  ptBR,
);
