import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { ReactComponent as FileError } from '../../../../../assets/fileError.svg';
import styles from '../styles.module.css';

export default () => {
  const history = useHistory();
  const reload = () => {
    history.go(0);
  };
  return (
    <Grid container className={styles.gridStyle}>
      <FileError />
      <Typography className={styles.titleStyle}>
        Oops! Não conseguimos exibir as informações.
      </Typography>
      <Typography className={styles.thinTextStyle}>
        Em caso de dúvidas, entre em contato com nossa Central de
        Relacionamento.
      </Typography>
      <Button
        variant="outlined"
        color="#A0001F"
        className={styles.backButton}
        onClick={reload}
      >
        Recarregar
      </Button>
    </Grid>
  );
};
