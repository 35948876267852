import React from 'react';
import styles from './styles.module.css';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Button } from '@material-ui/core';

function PlanSituation({
  onConfirmButtonClicked,
  buttonText,
  zeroPlan,
  invisible,
  emptyPlan,
  chargingActive
}) {

  const firstPaymentText = "Faça seu primeiro pagamento e acompanhe aqui o histórico de custo dos serviços.";
  const activatePlanText = "Para ativar seu plano, é necessário que você realize o pagamento da assinatura.";

  const emptyPlantitle = "Você não possui um plano contratado";
  const emptyPlanText = "Entre em contato com um Gerente de contas e contrate um plano para ter acesso total a Plataforma FortesPay empresas.";

  const zeroPlanTitle = "Histórico de pagamento dos serviços";

  const planNeedsToActivateTitle = "Seu plano precisa ser ativado";

  const title = emptyPlan
    ? emptyPlantitle
    : zeroPlan
    ? zeroPlanTitle
    : planNeedsToActivateTitle;

  const text = emptyPlan
    ? emptyPlanText
    : zeroPlan
    ? firstPaymentText
    : activatePlanText;

  return (
    <div style={{display: invisible && 'none'}} className={styles.container}>
      <InfoOutlinedIcon className={styles.iconInfo} />
      <p>
        {title}
      </p>
      <span className={emptyPlan ? styles.textNotRegistered : styles.text}>
        {text}
      </span>

      {!emptyPlan && (
        <Button
          className={styles.activateButton}
          onClick={onConfirmButtonClicked}
          disabled={!chargingActive}
        >
          <span>
            {zeroPlan ?
            'fazer 1º pagamento' : 'Ativar agora'}
          </span>
        </Button>
      )}
    </div>
  );
}

export default PlanSituation;
