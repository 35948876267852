import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import validarCpf from 'validar-cpf';
import Lottie from 'react-lottie';

import {
  CircularProgress,
  FormHelperText,
  Typography,
  TextField,
} from '@material-ui/core';
import { Button } from '@material-ui/core';

import ProgressToolBar from '../../../components/ProgressToolBar';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

import formatCPF from '../../../utils/formatCPF';
import { isMobile } from '../../../utils/breakpoints';
import ValidadeCpf from './utils/service';

import styles from './styles.module.css';

import peoplesAnimation from '../../../lotties/peoples.json';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: peoplesAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default () => {
  const history = useHistory();

  const [cpf, setCpf] = useState('');

  const [employer, setEmployer] = useState({});

  const [openModalDismissedEmployee, setOpenModalDismissedEmployee] = useState(
    false,
  );
  const [openModalExistingEmployee, setOpenModalExistingEmployee] = useState(
    false,
  );
  const [AlreadyRegisteredCPF, setAlreadyRegisteredCPF] = useState(false);

  const [errorCPF, setErrorCPF] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [errorMessageCPF, setErrorMessageCPF] = useState('');

  const [
    openModalDefinitelyReprovedEmployee,
    setOpenModalDefinitelyReprovedEmployee,
  ] = useState(false);

  const [
    openModalPendingReprovedEmployee,
    setOpenModalPendingReprovedEmployee,
  ] = useState(false);

  const companyCodeValue = sessionStorage.getItem('currentCompanyCode');

  async function searchToken() {
    const token = await sessionStorage.getItem('@ContaSoma-Token');
    return token;
  }

  useEffect(() => {
    sessionStorage.removeItem('EMPLOYEE_REGISTRATION_DATA');
    sessionStorage.removeItem('employeeDetails');
  }, []);

  const onNextClicked = e => {
    e.preventDefault();
    const validate = validarCpf(cpf);

    validate
      ? searchToken().then(token => {
          setIsLoading(true);
          const requestOptions = {
            headers: {
              Authorization: token,
            },
          };
          ValidadeCpf.getCpf({
            cpfValue: cpf,
            companyCodeValue,
            requestOptions,
            setErrorMessageCPF: errorMessage => {
              setErrorMessageCPF(errorMessage);
            },
            setStates: data => {
              if (data.linked && data.employeeCode && data.dismissed) {
                setEmployer(data.employeeCode);
                setOpenModalDismissedEmployee(true);
                setIsLoading(true);
              } else if (
                data.assured &&
                !data.isEmployee &&
                data.employeeCode
              ) {
                setEmployer(data.employeeCode);
                setOpenModalExistingEmployee(true);
                setIsLoading(true);
              } else if (data.linked && data.dismissed === false) {
                setAlreadyRegisteredCPF(true);
                setIsLoading(true);
              } else if (data.employeeCode && !data.dismissed && !data.linked) {
                setEmployer(data.employeeCode);
                setOpenModalExistingEmployee(true);
                setIsLoading(true);
              } else if (data.situationType === 'DEFINITIVELY_REPROVED') {
                setOpenModalDefinitelyReprovedEmployee(true);
              } else if (
                data.situation === 'REPROVED' &&
                data.situationType === 'PENDING' &&
                data.newAttemptAvailable === false
              ) {
                setOpenModalPendingReprovedEmployee(true);
              } else if (
                data.newAttemptAvailable === true &&
                data.employeeCode === null &&
                !data.dismisse &&
                !data.linked
              ) {
                sessionStorage.setItem(
                  'useTopBar',
                  JSON.stringify({
                    name: 'Cadastro individual',
                    step: 2,
                    route: history.location.pathname,
                  }),
                );

                sessionStorage.setItem(
                  'EMPLOYEE_REGISTRATION_DATA',
                  JSON.stringify({
                    cpf,
                    employeeCode: '',
                  }),
                );

                history.push('/employees/register');
                setIsLoading(true);
              }
              setIsLoading(false);
            },
          });
        })
      : setErrorCPF(true);
    setIsLoading(false);
  };

  const onBackClicked = () => {
    history.push('/employees');
  };

  function alreadyEmployeeModalClicked() {
    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: 'Cadastro individual',
        step: 2,
        route: history.location.pathname,
      }),
    );

    sessionStorage.setItem(
      'EMPLOYEE_REGISTRATION_DATA',
      JSON.stringify({
        cpf,
        employeeCode: employer,
      }),
    );

    history.push('/employees/register');
  }

  return (
    <div className={styles.container}>
      <Typography className={styles.titleStyle}>
        Cadastro individual de colaborador(a)
      </Typography>

      <div
        className={isMobile ? styles.mobileContainerSize : styles.containerSize}
      >
        <div
          className={isMobile ? styles.mobileContentStyle : styles.contentSize}
        >
          <div className={styles.logoArea}>
            <Lottie
              options={animationOptions}
              width={288}
              height={188}
              style={{ marginBottom: 42 }}
            />
          </div>

          <div className={styles.textContainerStyle}>
            <Typography className={styles.mediumTextStyle}>
              Boas vindas! Vamos guiá-lo durante o processo.
            </Typography>

            <Typography className={styles.mediumTextStyle}>
              Quando terminarmos, seu colaborador terá acesso a uma conta
              FortesPay e todos os seus benefícios!
            </Typography>

            <Typography className={styles.mobileMediumText}>
              Boas vindas!
            </Typography>
          </div>

          <div className={styles.formContainerStyle}>
            <Typography className={styles.biggerTextStyle}>
              {isMobile
                ? 'Para começar, qual é o CPF do funcionário?'
                : 'Para começar, qual é o CPF do(a) colaborador(a)? 😃'}
            </Typography>

            <div className={styles.form}>
              <TextField
                disabled={isLoading}
                required
                variant="outlined"
                placeholder="CPF"
                value={formatCPF(cpf)}
                onChange={({ target: { value } }) => setCpf(value)}
                className={styles.formTextFieldStyle}
                error={AlreadyRegisteredCPF || errorCPF}
                id="outlined-error"
                inputProps={{
                  maxLength: 14,
                  inputMode: 'numeric',
                }}
              />

              <Button
                type="submit"
                disabled={cpf.length < 2}
                variant="outlined"
                onClick={onNextClicked}
                className={styles.continueButtonStyle}
              >
                {isLoading ? (
                  <CircularProgress className={styles.circularLoadingStyle} />
                ) : (
                  'Continuar'
                )}
              </Button>
            </div>

            <FormHelperText className={styles.formHelperText}>
              {(AlreadyRegisteredCPF &&
                'Este CPF já está cadastrado. Digite outro CPF.') ||
                (errorCPF && 'CPF incorreto. Por favor, digite novamente.') ||
                errorMessageCPF}
            </FormHelperText>
          </div>

          <ConfirmationDialog
            open={openModalDismissedEmployee}
            onCloseDialog={() => setOpenModalDismissedEmployee(false)}
            title="Este(a) colaborador(a) foi demitido. Deseja continuar o cadastro?"
            text="Ao continuar o cadastro, você poderá revisar as informações do(a) colaborador(a) antes de finalizar."
            ignoreText="Salvar e Solicitar Cartões"
            onConfirmClicked={alreadyEmployeeModalClicked}
            confirmText="Continuar"
            cancelText="Cancelar"
          />

          <ConfirmationDialog
            open={openModalExistingEmployee}
            onCloseDialog={() => setOpenModalExistingEmployee(false)}
            title="Identificamos que este CPF é de um cliente FortesPay. Vamos resgatar as informações do banco de dados."
            text="No próximo passo, o formulário já estará preenchido com as informações do nosso banco de dados.
            Você poderá revisá-las antes de finalizar."
            ignoreText="Salvar e Solicitar Cartões"
            onConfirmClicked={() => {
              sessionStorage.setItem(
                'EMPLOYEE_REGISTRATION_DATA',
                JSON.stringify({
                  cpf,
                  employeeCode: employer,
                }),
              );

              history.push('/employees/register');
            }}
            confirmText="Entendi"
            cancelText="Cancelar"
          />

          <ConfirmationDialog
            open={openModalDefinitelyReprovedEmployee}
            onCloseDialog={() => setOpenModalDefinitelyReprovedEmployee(false)}
            title="Este CPF não pode ter conta na Somapay. Deseja cadastrar os dados bancários do colaborador?"
            text="Para realizar o pagamento deste colaborador é necessário cadastrar os dados bancários para
            realizarmos uma transferência bancária."
            onConfirmClicked={() => {
              history.push('/employees/reproved-onboarding');
            }}
            confirmText="Sim"
            cancelText="Não"
          />

          <ConfirmationDialog
            open={openModalPendingReprovedEmployee}
            onCloseDialog={() => setOpenModalPendingReprovedEmployee(false)}
            title="Este colaborador possui pendências não resolvidas. Deseja resolvê-las?"
            text="Para realizar o pagamento deste colaborador é necessário cadastrar os dados bancários para
            realizarmos uma transferência bancária."
            onConfirmClicked={() => {
              history.push('/employees/reproved-onboarding');
            }}
            confirmText="Sim"
            cancelText="Não"
          />
        </div>
      </div>

      <ProgressToolBar
        currentStep={0}
        numberOfSteps={0}
        onBackClicked={onBackClicked}
        backButtonText="Voltar"
        disableNext
        hidden={isMobile}
      />
    </div>
  );
};
