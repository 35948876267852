import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          color: '#A0001F',
        },
        '&$focused $notchedOutline': {
          borderColor: '#A0001F',
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#A0001F',
        },
      },
    },
  },
});
