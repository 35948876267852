import {
  makeStyles,
  createStyles,
  createMuiTheme,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

export const theme = createMuiTheme(
  {
    overrides: {},
  },
  ptBR,
);

export const useStyles = makeStyles(theme =>
  createStyles({
    menuList: {
      marginTop: '48px',
      marginLeft: '-30px',
      wordBreak: 'break-all',
    },
    table: {
      maxWidth: '100%',
    },
    defaultCell: {
      width: '80px',
      padding: '15px',
    },
    searchBackground: {
      backgroundColor: '#F1F1F1',
      marginBottom: 15,
      borderRadius: 4,
    },
    seachFocusBackground: {
      backgroundColor: '#FFFFFF',
      marginBottom: 15,
      borderRadius: 4,
      border: '1px solid #A0001F',
    },
    inputSearch: {
      marginBottom: '15px',
      border: 'none',
    },
    iconColor: {
      color: '#707070',
    },
  }),
);

export const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export const styles = {
  headerTitleStyle: {
    fontSize: 36,
    fontWeight: 500,
    lineHeight: '36px',
    letterSpacing: '-1px',
    marginBottom: 15,
    fontFamily: 'DM Sans',
  },
  editButton: {
    padding: '10px 20px',
    color: '#A0001F',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  datePickerStyle: {
    width: '100%',
  },
  editSituationButton: {
    color: '#A0001F',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    width: '180px',
    marginLeft: '-29px',
    marginTop: '33px',
    marginBottom: '25px',
    display: 'flex',
  },
  editPictureButton: {
    display: 'flex',
    color: '#A0001F',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    width: '180px',
    marginBottom: '15px',
  },
  headerSubtitleStyle: {
    fontFamily: 'DM Sans',
    fontStyle: ' normal',
    fontWeight: ' 500',
    fontSize: 24,
  },
  headerLineItemTextStyle: {
    fontFamily: ' DM Sans',
    fontStyle: ' normal',
    fontWeight: ' 500',
    fontSize: 14,
    lineHeight: 1.8,
  },
  areaIcon: {
    marginLeft: '-150px',
    // width: '300px',
  },

  headerStatusCheckedTextStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 34,
    color: '#2F80ED',
    textAlign: 'end',
    lineHeight: '36px',
    marginBottom: '10px',
  },

  headerStatusFiredTextStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 34,
    color: '#4f4f4f',
    textAlign: 'end',
    lineHeight: '36px',
    marginBottom: '10px',
  },
  headerStatusLicenseTextStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 34,
    color: '#ff6e33',
    textAlign: 'end',
    lineHeight: '36px',
    marginBottom: '10px',
  },
  headerStatusVacationTextStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 34,
    color: '#ff6e33',
    textAlign: 'end',
    lineHeight: '36px',
    marginBottom: '10px',
  },
  headerStatusTextStyleIcon: {
    marginLeft: '7px',
  },
  headerCaptionTextStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 12,
    color: '#BDBDBD',
    textAlign: 'end',
  },
  headerItems: {
    flexGrow: 1,
  },
  headerEditButton: {
    textAlign: 'end',
  },
  spacingGrid: {
    height: 20,
  },
  tabItemStyle: {
    flexGrow: 1,
    border: '1px solid rgba(27, 24, 28, 0.1)',
    borderRadius: '0px 0px 4px 4px',
    height: 58,
    alignContent: 'center',
    padding: 15,
    alignItems: 'center',
  },
  tabItemOpenStyle: {
    flexGrow: 1,
    border: '1px solid rgba(27, 24, 28, 0.1)',
    height: 58,
    alignContent: 'center',
    padding: 15,
    alignItems: 'center',
  },
  tabItemContentStyle: {
    padding: 15,
    borderRight: '1px solid rgba(27, 24, 28, 0.1)',
    borderLeft: '1px solid rgba(27, 24, 28, 0.1)',
    borderBottom: '1px solid rgba(27, 24, 28, 0.1)',
    backgroundColor: '#FFF',
  },
  tabTitleStyle: {
    fontFamily: 'DM Sans',
    fontStyle: ' normal',
    fontWeight: ' 500',
    fontSize: 20,
  },
  tabItemContainer: {
    margin: '10px 0px',
    backgroundColor: '#FFF',
    boxShadow: '0px 2px 3px 0px rgba(0,0,0,0.05)',
  },
  titleAreaTabItem: {
    flexGrow: 1,
  },
  formMounterSwitch: {
    minWidth: '100%',
  },
  formMounterInputLabel: {
    padding: 5,
  },
  paddingContainer: {
    padding: 15,
  },
  aditionalFieldsTextStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#828282',
  },
  dividerStyle: {
    height: 1,
    backgroundColor: 'rgba(27, 24, 28, 0.1)',
    marginBottom: 15,
  },

  containerRadio: {
    alignContent: 'center',
    justifyContent: 'center',
  },
  fieldItemFormMounter: {
    alignSelf: 'center',
  },
  radioGroupContainer: {
    justifyContent: 'center',
    padding: '5px 0px',
    alignItems: 'center',
  },
  gridItemsRadioGroup: {
    flexGrow: 1,
  },
  iconStyle: {
    padding: 1,
    marginRight: 5,
    transition: 'all ease 0.3s',
  },
  desktopPaperStyle: {
    position: 'fixed',
    width: 'calc(100% - 280px)',
    bottom: 0,
    marginLeft: '-28px',
    boxShadow: '0 0 3px 0 #9E9E9E',
  },
  nextButtonfadedStyle: { backgroundColor: '#9778ad', color: '#ffffff' },
  cancelButtonStyle: {
    color: '#A0001F',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  saveButtonStyle: { color: '#ffffff', backgroundColor: '#A0001F' },
  nextButtonFinalStepStyle: { color: '#ffffff', backgroundColor: '#E4282C' },
  mobilePaperStyle: {
    position: 'absolute',
    bottom: 5,
    width: '90vw',
  },
  mobileSpacing: { justifyContent: 'space-evenly' },
  loadingStyle: { color: '#A0001F' },
  formMounterFields: {
    color: 'black',
  },
  emptyContainerSpacing: {
    padding: 30,
    justifyContent: 'center',
  },
  emptyMessageTitle: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '15px',
    color: '#4F4F4F',
    letterSpacing: '-1px',
    marginBottom: 15,
    fontFamily: 'DM Sans',
    textAlign: 'center',
  },
  emptyMessageSubtitle: {
    fontSize: 15,
    fontWeight: 500,
    color: '#4F4F4F',
    lineHeight: '15px',
    letterSpacing: '-1px',
    marginBottom: 15,
    fontFamily: 'DM Sans',
    textAlign: 'center',
  },
};

export default styles;
