const styles = {
  titleStyle: {
    fontSize: 34,
    fontWeight: 500,
    lineHeight: '36px',
    letterSpacing: '-1px',
    fontFamily: 'DM Sans',
    marginBottom: 60,
  },
  logoStyle: {
    marginBottom: 15,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  contentStyle: {
    height: '65vh',
    width: '100%',
    flex: 1,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  mobileContentStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  biggerTextStyle: {
    fontSize: 20,
    fontWeight: 550,
    letterSpacing: '-0.5px',
    fontFamily: 'DM Sans',
    marginBottom: '10px',
  },
  smallerTextStyle: {
    marginTop: '-8px',
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    fontFamily: 'DM Sans',
    marginBottom: '20px',
  },
  smallerTextStyleRed: {
    color: '#E4282C',
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    fontFamily: 'DM Sans',
  },
  table: {
    borderCollapse: 'collapse',
    marginBottom: 10,
    backgroundColor: '#fff',
  },

  th: {
    border: 'solid 0.05px',
    padding: '10px',
    textAlign: 'start',
    fontFamily: 'DM Sans',
  },
  tr: {
    border: 'solid 0.05px',
    padding: '10px',
    fontFamily: 'DM Sans',
  },
  td: {
    maxWidht: '80px',
    border: 'solid 0.05px',
    padding: '10px',
    fontFamily: 'DM Sans',
  },
  marginStyle: {
    marginTop: '2em',
  },
  buttonStyle: {
    height: '18vh',
    width: '100%',
  },
  primaryColor: {
    color: '#A0001F',
    justifyContent: 'end',
  },
  dropzoneStyle: {
    width: '100%',
    height: '20vh',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#f1edf3',
    borderRadius: '12px',
    padding: '15px',
    alignItems: 'center',
  },
  buttonTextStyle: {
    color: '#A0001F',
    display: 'flex',
  },
  fileAvatarStyle: {
    color: '#A0001F',
    width: '58px',
  },
  fileTextStyle: {
    fontSize: '20px',
    lineHeight: '24px',
    color: '#A0001F',
  },
  fileButtonStyle: {
    color: '#A0001F',
    alignSelf: 'center',
  },
  textFieldStyle: {
    borderRadius: 4,
  },
  rootView: {
    minHeight: 550,
  },
};

export default styles;
