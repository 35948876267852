import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Lottie from 'react-lottie';

import {
  Button,
  Hidden,
  Typography,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import { Table } from '../../../components/Table';
import useTableConfig from './Hooks/useTableConfig';
import ErrorComponent from '../ConsultPayroll/UI/ErrorComponent';
import Filter from './UI/Filter';
import CardsDetails from './UI/CardsDetails';

import ProgressBar from '../../../components/ProgressToolBar';
import AuthorizePayrollModal from '../../../components/ProgressModal';
import LoadingModal from '../../../components/LoadingModal';
import BalancesModal from '../../../components/BalancesModal';
import InsufficientBalanceModal from '../../../components/BalancesModal';
import SuccessModal from '../../../components/SuccessModal';
import RescheduleDialog from '../../../components/RescheduleDialog';
import CancelPayrollDialog from '../../../components/CancelPayrollDialog';
import ErrorDownloadModal from '../../../components/PrimaryModal';
import CustomSnackbar from '../../../components/CustomSnackbar';
import { ResponsiveCard, ResponsiveList, BottomSheet } from './responsive';

import { pusherStatus, pusherEvents } from '../../../enums/pusher';
import { styles } from './styles';
import { ActionsBalance } from '../../../redux/store/Balances';
import { api } from '../../../services/api';
import { isMobile } from '../../../utils/breakpoints';
import convertBytesToBlobDowload from '../../../utils/convertBytesToBlobDowload';
import convertBytesToBlobDowloadXls from '../../../utils/convertBytesToBlobDownloadXls';
import convertBytesToBlobDownloadZip from '../../../utils/convertBytesToBlobDownloadZip';
import currencyFormatter from '../../../utils/currencyFormatter';
import { pusher } from '../../../utils/pusher';
import processErrorMessage from '../../../utils/processErrorMessage';
import style from './styles.module.css';

import { ReactComponent as PlusIcon } from '../../../assets/plus-icon.svg';
import { ReactComponent as MinusIcon } from '../../../assets/minusIcon.svg';

import { hasAuthorization, profiles } from '../../../utils/hasAuthorization';
import { isSubsidiaryCompanie } from '../../../utils/isSubsidiaryCompanie';
import { useCountdown } from '../../../hooks/useValidationForUnformattedDate/useCountdown';
import BottomBarActionsTable from './UI/BottomBarActionsTable';

import { PusherJs } from '../../../utils/pusherv2';
import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';
import useGeolocation from '../../../hooks/useGeolocation';

import usePayrollDetailsData from './Hooks/usePayrollDetailsData';
import usePayrollDetailsUtils from './Hooks/usePayrollDetailsUtils';

import {
  ANIMATION_OPTIONS,
  COUNTDOWN_TIME,
  AVAILABLE_PAYMENT_HOURS,
} from './constants';

const PayrollDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useGeolocation();

  const [severity, setSeverity] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [errorOnRequest, setErrorOnRequest] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);

  const [authorizePayrollModal, setAuthorizePayrollModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [channelData, setChannelData] = useState({});
  const [errorAuthorization, setErrorAuthorization] = useState(false);
  const [
    openImmediateReleaseSuccessModal,
    setOpenImmediateReleaseSuccessModal,
  ] = useState(false);

  const [openImmediateReleaseModal, setOpenImmediateReleaseModal] = useState(
    false,
  );
  const [insufficientFunds, setInsufficientFunds] = useState(false);
  const [rateValues, setRateValues] = useState({});
  const [loadingRates, setLoadingRates] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [openModalSuccess, setOpenModalSuccess] = useState(false);
  const [cancelButton, setCancelButton] = useState(false);
  const [circularLoading, setCircularLoading] = useState(false);
  const [cnabLoading, setCnabLoading] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [scheduleDate2, setScheduleDate] = useState(
    moment().format('YYYY-MM-DD'),
  );

  const [
    selectedAvailablePaymentHour,
    setSelectedAvailablePaymentHour,
  ] = useState('');
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openErrorDownloadModal, setOpenErrorDownloadModal] = useState(false);

  const [avaliableBalance, setAvaliableBalance] = useState({});
  const [insufficientBalanceModal, setInsufficientBalanceModal] = useState(
    false,
  );

  const [requestId, setRequestId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');

  const [pinValue, setPinValue] = useState(null);
  const [sendPinStatus, setSendPinStatus] = useState(null);

  const [actionsButtonAnchor, setActionsButtonAnchor] = useState('');

  const [depositAccountEncodedKey, setDepositAccountEncodedKey] = useState('');

  const [countdownHasStarted, setCountDownHasStarted] = useState(false);
  const { currentTime } = useCountdown(COUNTDOWN_TIME, countdownHasStarted);

  const channelReceiptPayment = useMemo(() => new PusherJs(), []);
  const [
    downloadMultipleReceiptsPercentage,
    setDownloadMultipleReceiptsPercentage,
  ] = useState(null);

  const isSubsidiary = isSubsidiaryCompanie();
  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));

  const {
    employees,
    setEmployees,
    config,
    pagination,
    length,
    isLoading,
    disableSelection,
    handleDownload,
    handleDownloadAll,
    handleDataRequest,
  } = useTableConfig(setPaymentType, setDepositAccountEncodedKey);

  const {
    accounts,
    accountSelected,
    accountSelectedToPayrollDetails,
    companyCode,
    pinRequired,
    payrollData: {
      status,
      fileName,
      scheduleDate,
      paymentDate,
      totalAmount,
      nsu,
      creationUserName,
      approvalUserName,
      paymentsAmount,
      feesAmount,
      expressPaymentAmount,
      importDate,
      approvalDate,
      signatureAmount,
      expressPaymentUserName,
      expressPaymentDate,
    },
  } = usePayrollDetailsData({ depositAccountEncodedKey });

  const {
    formatNumber,
    today,
    dateValidation,
    timeValidation,
    formatTotalValue,
    formatAmount,
  } = usePayrollDetailsUtils();

  const multipleRowsSelected = rowsSelected.length > 1;

  const isMoreThanOneAccount = accounts.length > 1;

  const { PROCESSED_PAYROLL_SUCCESS, PROCESSING } = pusherStatus;

  const EMAIL_MASKED = userEmail?.replace(
    /^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c,
  );

  window.onscroll = function(e) {
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
      setOpenBottomSheet(true);
    }
  };

  const confirmAuthorization = () => {
    const requestOptions = {
      headers: {
        requestId,
        pin: pinValue,
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
        ...location,
      },
    };

    api.put(`employeesPayments/${nsu}/authorize`, '', requestOptions).catch(
      ({
        response: {
          data: { errors },
        },
      }) => {
        if (errors[0]?.errorReason === 'FILE_ALREADY_PROCESSING') {
          setErrorAuthorization(true);
        }

        setErrorMessage(errors[0]?.errorReason);
      },
    );
  };

  useEffect(() => {
    if (
      moment(scheduleDate2).format('YYYY-MM-DD') ===
      moment().format('YYYY-MM-DD')
    ) {
      if (moment().format('HH:00:00') < '21:00:00') {
        setSelectedAvailablePaymentHour(
          moment()
            .add(1, 'hour')
            .format('HH:00:00'),
        );
      } else {
        setSelectedAvailablePaymentHour('');
      }
    } else if (moment().format('HH:00:00') < '21:00:00') {
      setSelectedAvailablePaymentHour(moment().format('HH:00:00'));
    } else {
      setSelectedAvailablePaymentHour('04:00:00');
    }
  }, [scheduleDate2]);

  const onBackClicked = () => {
    history.push('/payroll');
  };

  const requestPdf = () => {
    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
      },
      responseType: 'blob',
    };

    api
      .get(
        `/employeesPayments/paymentReport/${companyCode}/${nsu}`,
        requestOptions,
      )
      .then(({ data }) => {
        const nameDocument = `comprovante_pagamento_${nsu}`;
        convertBytesToBlobDowload(data, nameDocument);
      });
  };

  const requestXls = () => {
    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
      },
      responseType: 'blob',
    };

    api
      .get(
        `/employeesPayments/report/xls/${companyCode}/${nsu}`,
        requestOptions,
      )
      .then(({ data }) => {
        const nameDocument = `comprovante_pagamento_${nsu}`;
        convertBytesToBlobDowloadXls(data, nameDocument);
      });
  };

  const handleCnabDownload = () => {
    setCnabLoading(true);
    const requestOptions = {
      responseType: 'blob',
    };

    api
      .post(
        `files/employeespayments/return-file/generate/${nsu}`,
        requestOptions,
      )
      .then(({ data: { file, fileName } }) => {
        setCnabLoading(false);
        const binaryString = window.atob(file);
        const binaryLength = binaryString.length;
        const bytes = new Uint8Array(binaryLength);

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < binaryLength; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }

        const downloadUrl = window.URL.createObjectURL(new Blob([bytes]));

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${fileName}.txt`);

        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(error => {
        setCnabLoading(false);
        handleSnackbarStack().error(error.response.data.errors[0].errorDetail);
      });
  };

  const onBackClickedErrorComponent = () => history.push('/payroll');

  const getRates = () => {
    setErrorMessage('');
    setPinValue('');
    setLoadingRates(true);

    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
        accountOrigin: accountSelected?.origin,
      },
    };

    api
      .get(
        `/employeesPayments/expressPayment/fee/${companyCode}/${nsu}`,
        requestOptions,
      )
      .then(({ data }) => {
        const { errorMessage, missingBalance } = data;

        if (pinRequired) {
          preAuthorizePayroll(false);
        }

        if (errorMessage && missingBalance) {
          setInsufficientFunds(true);
        }

        setRateValues(data);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {},
      )
      .finally(() => {
        setLoadingRates(false);
      });
  };

  const onConfirmImmediateRelease = () => {
    setLoadingButton(true);

    const payrollId = rateValues.nsu;

    const requestOptions = {
      headers: {
        requestId,
        pin: pinValue,
        accountOrigin: accountSelected?.origin,
      },
    };

    api
      .post(`employeesPayments/expressPayment/${payrollId}`, '', requestOptions)
      .then(() => {
        setOpenImmediateReleaseSuccessModal(true);
        setInsufficientFunds(false);
        setOpenImmediateReleaseModal(false);
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          setErrorMessage(errors[0]?.errorDetail);
        },
      )
      .finally(() => {
        setLoadingButton(false);
      });
  };

  const totalPayrollAmount = rateValues.totalAmount + rateValues.feeAmount;

  const goToDeposits = () => {
    history.push('/cash-in');
  };

  const onChangeDate = value => {
    setMessageError('');
    const dateValue = moment(value?._d).format('DD/MM/YYYY');

    if (dateValue === today && paymentType !== 'Rescisão') {
      setMessageError(
        'A data não pode ser selecionada para o tipo de pagamento',
      );
    } else {
      return setScheduleDate(value);
    }
  };

  const alterDatePayroll = () => {
    const date = `${moment(scheduleDate2).format(
      'YYYY-MM-DD',
    )}T${selectedAvailablePaymentHour}`;

    setCircularLoading(true);

    const requestOptions = {
      headers: {
        newScheduleDate: date,
      },
    };
    api
      .put(`/employeesPayments/${nsu}/reschedule`, '', requestOptions)
      .then(() => {
        setCancelButton(true);
        setOpenModalSuccess(true);
        setMessageError('');
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          return setMessageError(errors[0].errorDetail);
        },
      )
      .finally(() => setCircularLoading(false));
  };

  const confirmCancel = () => {
    setCircularLoading(true);

    api.put(`/employeesPayments/${nsu}/cancel`).finally(() => {
      setCircularLoading(false);
      setCancelButton(true);
      setOpenModalSuccess(true);
    });
  };

  const onPaymentClick = () => {
    setLoadingModal(true);
    setErrorMessage('');
    setPinValue('');

    const channel = pusher.subscribe(companyCode);

    channel.bind('AUTHORIZATION_PAYROLL', function(data) {
      setChannelData(data);
    });

    const requestBody = {
      nsuList: [nsu],
    };

    api
      .post(
        `/employeesPayments/batch/authorize/balance/${companyCode}`,
        requestBody,
      )
      .then(({ data }) => {
        if (data.insufficientBalance) {
          setAvaliableBalance(data);
          setInsufficientBalanceModal(true);
        } else if (pinRequired) {
          preAuthorizePayroll(false, () => setAuthorizePayrollModal(true));
        } else {
          setAuthorizePayrollModal(true);
        }
      })
      .finally(() => setLoadingModal(false));
  };

  const resetError = () => setErrorMessage('');

  function preAuthorizePayroll(isTryingSendCodeBySms, callback) {
    const nsuList = [Number(nsu)];

    const requestBody = {
      nsuList,
      emailNotReceived: isTryingSendCodeBySms,
    };

    const requestConfig = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
      },
    };

    setCountDownHasStarted(true);

    setTimeout(() => {
      setCountDownHasStarted(false);
    }, COUNTDOWN_TIME * 1000);

    api
      .put('/employeesPayments/pre-authorize', requestBody, requestConfig)
      .then(({ data }) => {
        setRequestId(data.requestId);
        setUserEmail(data.email);
        setSendPinStatus(data.status);

        if (data?.phoneNumber && data?.status === 'SMS_SENT') {
          setSeverity('success');
          setShowSnackbar(true);
          setSnackbarMessage(
            `O SMS foi enviado para o número: ${formatNumber(
              data?.phoneNumber,
            )}`,
          );

          setTimeout(() => {
            setShowSnackbar(false);
          }, 5000);
        }

        if (callback) callback();
      })
      .catch(error => handleShowSnackBarErrorMessage(error.response?.data));
  }

  function handleShowSnackBarErrorMessage(error) {
    setSnackbarMessage(processErrorMessage(error));
    setSeverity('error');
    setShowSnackbar(true);
  }

  function handlePayrollSuccess() {
    setAuthorizePayrollModal(false);
    dispatch(ActionsBalance.getBalances());
    history.push('/payroll');
  }

  function handleImmediateRealeseSuccess() {
    setOpenImmediateReleaseSuccessModal(false);
    dispatch(ActionsBalance.getBalances());
    history.push('/payroll');
  }

  function handleConfirmCancelPayroll() {
    setOpenCancelModal(false);
    dispatch(ActionsBalance.getBalances());
    history.push('/payroll');
  }

  function handleDownloadBatchEmployeeReceiptPayments() {
    const requestBody = rowsSelected;

    channelReceiptPayment.listen(
      companyCode,
      pusherEvents.RECEIPT_PAYMENT,
      async pusherData => {
        if (pusherData.status === pusherStatus.PROCESSING) {
          setDownloadMultipleReceiptsPercentage(pusherData.percentage);
        }

        if (pusherData.status === pusherStatus.PROCESSED) {
          handleGetReceiptPayments(pusherData.data);

          setDownloadMultipleReceiptsPercentage(null);

          channelReceiptPayment.pop();
        }
      },
    );

    api
      .post(
        `employeesPayments/receiptPayment/batch/${companyCode}/${nsu}/nsu`,
        requestBody,
      )
      .then(() => {
        handleSnackbarStack().success(
          'Lista de comprovantes enviada com sucesso! O download irá começar em breve',
        );
      })
      .catch(error => {
        if (error?.response?.data) {
          handleSnackbarStack().error(error?.response?.data);
        } else {
          handleSnackbarStack().error(
            'Houve um erro ao enviar lista comprovantes',
          );
        }
      })
      .finally(() => {
        setRowsSelected([]);
      });
  }

  function handleGetReceiptPayments(receiptUrl) {
    const requestOptions = {
      headers: {
        url: receiptUrl,
      },

      responseType: 'blob',
    };

    api
      .get('employeesPayments/receiptPayment/file', requestOptions)
      .then(({ data }) => {
        const documentName = `folha_pagamento_${nsu}`;
        convertBytesToBlobDownloadZip(data, documentName);
        handleSnackbarStack().success('Download concluído com sucesso!');
      })
      .catch(error => {
        if (error?.response?.data) {
          handleSnackbarStack().error(error?.response?.data);
        } else {
          handleSnackbarStack().error('Houve um erro ao baixar comprovantes');
        }
      });
  }

  function handleChangePinValue(event) {
    setPinValue(event.target.value);
  }

  const handleActionsMenu = event => {
    setActionsButtonAnchor(event.currentTarget);
  };

  if (errorOnRequest) {
    return (
      <ErrorComponent
        onBackClickedErrorComponent={onBackClickedErrorComponent}
      />
    );
  }

  return (
    <div className={style.container}>
      <Backdrop open={cnabLoading} style={{ color: '#FFF', zIndex: 10000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className={style.header}>
        <Typography style={styles.titleStyle}>Visualizar Detalhes</Typography>
        <Button
          variant="contained"
          className={style.downloadTicket}
          startIcon={<DescriptionOutlinedIcon />}
          onClick={handleActionsMenu}
        >
          <Typography>Ações</Typography>
        </Button>
        <Menu
          id="simple-menu"
          keepMounted
          anchorEl={actionsButtonAnchor}
          open={Boolean(actionsButtonAnchor)}
          onClose={() => setActionsButtonAnchor('')}
          className={styles.menu}
        >
          <MenuItem onClick={requestPdf}>Baixar relatório (.PDF)</MenuItem>
          <MenuItem onClick={requestXls}>Baixar relatório (.XLS)</MenuItem>
          <MenuItem onClick={handleDownloadAll}>
            Baixar relatório por centro de custo
          </MenuItem>
          <MenuItem onClick={handleCnabDownload}>
            Baixar retorno CNAB 240
          </MenuItem>
        </Menu>
      </div>

      {isMobile ? (
        <ResponsiveCard
          status={status}
          fileName={fileName}
          paymentDate={paymentDate}
          nsu={nsu}
          creationUserName={creationUserName}
          approvalUserName={approvalUserName}
          paymentType={paymentType}
          totalValue={formatTotalValue(totalAmount)}
          paymentValidate={dateValidation}
          totalPayroll={formatTotalValue(paymentsAmount)}
          fees={formatTotalValue(feesAmount, true)}
          expressPayment={formatTotalValue(expressPaymentAmount, true)}
          importDate={importDate}
          criationDate={dateValidation(importDate)}
          criationTime={timeValidation(importDate)}
          authorizationDate={dateValidation(approvalDate)}
          authorizationTime={timeValidation(approvalDate)}
          loading={loading}
          scheduleDate={scheduleDate}
          expressPaymentUserName={expressPaymentUserName}
          expressPaymentDate={expressPaymentDate}
          depositAccount={accountSelectedToPayrollDetails}
          isMoreThanOneAccount={isMoreThanOneAccount}
          isSnackbarActive={showSnackbar}
          snackbarSeverity={severity}
          snackbarMessage={snackbarMessage}
        />
      ) : (
        <CardsDetails
          status={status}
          fileName={fileName}
          paymentDate={paymentDate}
          nsu={nsu}
          creationUserName={creationUserName}
          approvalUserName={approvalUserName}
          paymentType={paymentType}
          totalValue={formatTotalValue(totalAmount)}
          paymentValidate={dateValidation}
          totalPayroll={formatTotalValue(paymentsAmount)}
          fees={formatTotalValue(feesAmount, true)}
          expressPayment={formatTotalValue(expressPaymentAmount, true)}
          importDate={importDate}
          criationDate={dateValidation(importDate)}
          criationTime={timeValidation(importDate)}
          authorizationDate={dateValidation(approvalDate)}
          authorizationTime={timeValidation(approvalDate)}
          loading={loading}
          signatureValue={formatTotalValue(signatureAmount)}
          signatureAmount={signatureAmount}
          scheduleDate={scheduleDate}
          expressPaymentUserName={expressPaymentUserName}
          expressPaymentDate={expressPaymentDate}
          depositAccount={accountSelectedToPayrollDetails}
          isMoreThanOneAccount={isMoreThanOneAccount}
        />
      )}

      <Filter
        nsu={nsu}
        setData={setData}
        setEmployees={setEmployees}
        setPaymentType={setPaymentType}
        pagination={pagination}
        setLoading={setLoading}
        loading={loading}
        paymentType={paymentType}
      />

      {isMobile ? (
        <ResponsiveList
          employees={employees}
          loading={loading}
          requestPdf={requestPdf}
        />
      ) : (
        <>
          <div style={styles.tableMargin} />
          <Table
            identifier="cpf"
            data={employees}
            length={length}
            tableConfig={config}
            selectable
            hasPagination
            isLoading={isLoading}
            handleSelection={setRowsSelected}
            disableSelection={disableSelection}
            requests={handleDataRequest}
          />
          <div style={styles.tableMargin} />
        </>
      )}

      <Hidden smUp>
        <BottomSheet
          openBottomSheet={openBottomSheet}
          status={status}
          setAuthorizePayrollModal={setAuthorizePayrollModal}
          setOpenImmediateReleaseModal={setOpenImmediateReleaseModal}
          setOpenBottomSheet={setOpenBottomSheet}
          setOpenRescheduleModal={setOpenRescheduleModal}
          setOpenCancelModal={setOpenCancelModal}
          getRates={getRates}
          onPaymentClick={onPaymentClick}
          handleDownload={handleDownload}
        />
      </Hidden>

      <Hidden xsDown>
        <ProgressBar disableNext onBackClicked={onBackClicked} />
      </Hidden>

      <Hidden xsDown>
        <BottomBarActionsTable
          selected={rowsSelected}
          onCancelClick={() => setRowsSelected([])}
          onConfirmClick={handleDownloadBatchEmployeeReceiptPayments}
          confirmText={
            downloadMultipleReceiptsPercentage
              ? `${downloadMultipleReceiptsPercentage}%`
              : 'Baixar comprovantes'
          }
          multipleRowsSelected={multipleRowsSelected}
        />
      </Hidden>

      <AuthorizePayrollModal
        pinOnConfirmPayroll={pinRequired}
        codeVerification={channelData?.status !== PROCESSED_PAYROLL_SUCCESS}
        codeValue={pinValue}
        handleChangeCodeValue={handleChangePinValue}
        errorMessage={errorMessage}
        emailLabel={EMAIL_MASKED}
        open={authorizePayrollModal}
        title={
          channelData?.status === PROCESSING
            ? 'Autorização em processamento...'
            : channelData?.status === PROCESSED_PAYROLL_SUCCESS
            ? 'Pagamento Autorizado!'
            : errorAuthorization
            ? 'Um outro usuário já realizou está ação.'
            : 'Autorizar este pagamento?'
        }
        text={
          channelData?.status === PROCESSING ? (
            'O processamento pode levar alguns minutos. Após a conclusão, você pode voltar a usar a plataforma normalmente.'
          ) : channelData?.status === PROCESSED_PAYROLL_SUCCESS ? (
            <span>
              Caso precise atencipar o pagamento, a{' '}
              <strong>liberação imediata</strong> já está disponível.
            </span>
          ) : errorAuthorization ? (
            'A autorização para o pagamento desta folha já foi dada e será executada na data agendada.'
          ) : (
            'Ao autorizar, o pagamento será executado na data agendada.'
          )
        }
        confirmButtonText={
          channelData?.status === PROCESSING
            ? `${channelData?.percentage || '0'} %`
            : channelData?.status === PROCESSED_PAYROLL_SUCCESS
            ? 'Fechar'
            : errorAuthorization
            ? 'Entendi'
            : 'Autorizar'
        }
        onConfirmClicked={
          channelData?.status === PROCESSED_PAYROLL_SUCCESS
            ? () => {
                handlePayrollSuccess();
              }
            : errorAuthorization
            ? () => setAuthorizePayrollModal(false)
            : confirmAuthorization
        }
        cancelButtonText={
          !(
            channelData?.status === PROCESSED_PAYROLL_SUCCESS ||
            errorAuthorization
          ) && 'Cancelar'
        }
        onCancelButtonClicked={() => setAuthorizePayrollModal(false)}
        progressLoading={channelData?.status === PROCESSING}
        progress={channelData?.percentage}
        sendPinStatus={sendPinStatus}
        countdownTime={currentTime}
        countdownHasStarted={countdownHasStarted}
        handleTrySendCodeBySms={() => preAuthorizePayroll(true)}
      />

      <InsufficientBalanceModal
        pinOnConfirmPayroll={pinRequired}
        codeVerification={!avaliableBalance?.insufficientBalance}
        codeValue={pinValue}
        handleChangeCodeValue={handleChangePinValue}
        errorMessage={errorMessage}
        emailLabel={EMAIL_MASKED}
        title={
          avaliableBalance?.insufficientBalance
            ? 'Saldo insuficiente'
            : 'Autorizar pagamento?'
        }
        text={
          avaliableBalance?.insufficientBalance
            ? 'Você não possui saldo suficiente para autorizar o pagamento.'
            : 'Ao autorizar, o pagamento desta importação será processado e não poderá ser desfeito.'
        }
        open={insufficientBalanceModal}
        confirmButtonText={
          avaliableBalance?.insufficientBalance ? 'Depósito' : 'Autorizar'
        }
        cancelButtonText="Cancelar"
        onCancelButtonClicked={() => setInsufficientBalanceModal(false)}
        firstLabel="Seu Saldo"
        firstValue={currencyFormatter(avaliableBalance.balance)}
        secondLabel="Valor da Folha"
        secondValue={currencyFormatter(avaliableBalance.fileAmount)}
        thirdLabel="Diferença"
        thirdValue={currencyFormatter(avaliableBalance.difference)?.replace(
          '-',
          '',
        )}
        alertText="Deposite o valor pendente para conseguir realizar o pagamento."
        onConfirmClicked={
          avaliableBalance?.insufficientBalance
            ? () => history.push('/cash-in')
            : confirmAuthorization
        }
        icon={<MinusIcon />}
        loadingButton={loadingButton}
        disabledConfirmButton={
          loading ||
          loadingButton ||
          (avaliableBalance?.insufficientBalance &&
            (!hasAuthorization(ACCESS_PROFILES, [
              profiles.ADMIN,
              profiles.FINANCIAL,
            ]) ||
              isSubsidiary))
        }
      />

      <LoadingModal
        open={loadingModal}
        title="Aguarde alguns instantes..."
        height={80}
        width={80}
        image={<CircularProgress color="primary" />}
      />

      <BalancesModal
        pinOnConfirmPayroll={pinRequired}
        codeVerification={!insufficientFunds}
        codeValue={pinValue}
        handleChangeCodeValue={handleChangePinValue}
        errorMessage={errorMessage}
        resetError={resetError}
        emailLabel={EMAIL_MASKED}
        open={openImmediateReleaseModal}
        title={
          insufficientFunds
            ? 'Saldo insuficiente'
            : 'Liberar este pagamento imediatamente?'
        }
        text={
          insufficientFunds
            ? 'Você não possui saldo suficiente para liberação imediata do pagamento desta folha.'
            : 'O pagamento será efetuado em até 1 hora, a partir do momento da confirmação e não poderá ser cancelado.'
        }
        cancelButtonText="Cancelar"
        onCancelButtonClicked={() => setOpenImmediateReleaseModal(false)}
        confirmButtonText={insufficientFunds ? 'Depósito' : 'Liberar'}
        onConfirmClicked={
          insufficientFunds ? goToDeposits : onConfirmImmediateRelease
        }
        loading={loadingRates}
        loadingButton={loadingButton}
        firstLabel={insufficientFunds ? 'Seu saldo' : 'Folha'}
        firstValue={formatAmount(
          insufficientFunds
            ? rateValues?.availableBalance
            : rateValues.totalAmount,
        )}
        secondLabel={insufficientFunds ? 'Valor da Folha' : 'Tarifa'}
        secondValue={formatAmount(
          insufficientFunds ? rateValues.totalAmount : rateValues.feeAmount,
        )}
        thirdLabel={insufficientFunds ? 'Diferença' : 'Total da Folha'}
        thirdValue={formatAmount(
          insufficientFunds ? rateValues.missingBalance : totalPayrollAmount,
        )}
        alertText={
          insufficientFunds
            ? 'Deposite o valor pendente para conseguir realizar a liberação.'
            : 'A tarifa será cobrada após a execução do pagamento.'
        }
        icon={insufficientFunds ? <MinusIcon /> : <PlusIcon />}
        disabledConfirmButton={
          loading ||
          loadingButton ||
          (insufficientFunds &&
            (!hasAuthorization(ACCESS_PROFILES, [
              profiles.ADMIN,
              profiles.FINANCIAL,
            ]) ||
              isSubsidiary))
        }
        sendPinStatus={sendPinStatus}
      />

      <SuccessModal
        open={openImmediateReleaseSuccessModal}
        onCloseDialog={handleImmediateRealeseSuccess}
        title={
          status === 'Aguardando autorização'
            ? 'Pagamento autorizado e liberado com sucesso!'
            : 'Pagamento liberado com sucesso!'
        }
        closeButtonText="Fechar"
        text="Em até 1 hora todos os pagamentos serão realizados."
      />

      <RescheduleDialog
        open={openRescheduleModal}
        title={
          !openModalSuccess
            ? 'Defina uma nova data de agendamento'
            : 'Data alterada!'
        }
        text={
          !openModalSuccess
            ? 'Ao prosseguir, a data será alterada de acordo com a sua escolha.'
            : 'Em instantes atualizaremos a lista de pagamentos com a nova data deste item.'
        }
        paymentType={paymentType}
        cancelButton={cancelButton}
        circularLoading={circularLoading}
        onCloseDialog={() => setOpenRescheduleModal(false)}
        confirmText={!openModalSuccess ? 'Alterar' : 'Fechar'}
        onChangeDate={onChangeDate}
        date={scheduleDate2}
        style={{ backgrounColor: '#000' }}
        onConfirmClicked={
          !openModalSuccess
            ? () => {
                alterDatePayroll();
              }
            : () => {
                history.push('/payroll');
                setOpenRescheduleModal(false);
              }
        }
        cancelText={!openModalSuccess ? 'Cancelar' : null}
        openModalSuccess={openModalSuccess}
        errorMessage={messageError || ''}
        availablePaymentHours={AVAILABLE_PAYMENT_HOURS}
        selectedAvailablePaymentHour={selectedAvailablePaymentHour}
        setSelectedAvailablePaymentHour={setSelectedAvailablePaymentHour}
      />

      <CancelPayrollDialog
        cancelButton={cancelButton}
        circularLoading={circularLoading}
        open={openCancelModal}
        onCloseDialog={() => setOpenCancelModal(false)}
        title={
          !openModalSuccess ? 'Desfazer agendamento?' : 'Pagamento cancelado!'
        }
        text={
          !openModalSuccess
            ? 'Ao fazer isso, o pagamento não será realizado e não poderá ser reagendado.'
            : 'Pronto, seu agendamento foi desfeito e o pagamento cancelado!'
        }
        confirmText={!openModalSuccess ? 'Desfazer' : 'Fechar'}
        onConfirmClicked={
          !openModalSuccess
            ? () => {
                confirmCancel();
              }
            : () => {
                handleConfirmCancelPayroll();
              }
        }
        cancelText="Cancelar"
      />

      <ErrorDownloadModal
        title="Houve um erro ao realizar o download"
        text="Tente novamente mais tarde"
        open={openErrorDownloadModal}
        confirmButtonText="Entendi"
        onConfirmClicked={() => setOpenErrorDownloadModal(false)}
      />

      <CustomSnackbar
        open={showSnackbar}
        severity={severity}
        close={() => setShowSnackbar(false)}
        message={snackbarMessage}
      />
    </div>
  );
};

export default PayrollDetails;
