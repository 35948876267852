import React from 'react';

import styles from './styles.module.css';

export default function Badge({ title, color = '#E4282C' }) {
  return (
    <div className={styles.badge} style={{ background: color }}>
      {title}
    </div>
  );
}
