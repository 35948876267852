import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyItems: 'center',
      textAlign: 'center',
      padding: '47px 37px 47px 37px',
      overflow: 'hidden'
    },
    image: {
      margin: '0 auto',
      width: 200,
      height: 200
    },
    title: {
      color: '#1B181C',
      fontSize: '1.5rem',
      fontWeight: 500,
      letterSpacing: '-0.82px',
      marginTop: '0.9375rem',
      lineHeight: '110%',
      marginBottom: '0.75rem',
    },
    text: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '0.9375rem',
      lineHeight: '1.125rem',
      textAlign: 'center',
      letterSpacing: 0.5,
      color: '#545255',
    },
    buttonsGroup: {
      marginTop: '1.875rem',
      display: 'flex',
      justifyContent: 'center',
    },
    closeButton: {
      border: '1px solid #A0001F',
      boxSizing: 'border-box',
      borderRadius: '6px',
      padding: '11px 12px',
      width: '8.75rem',
    },
    closeButtonText: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      letterSpacing: '0.085em',

      textTransform: 'uppercase',
      color: '#A0001F',
    }
  })
);

export default useStyles;
