import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = {
  titleStyle: {
    fontSize: 34,
    fontWeight: 700,
    marginBottom: 30,
  },
  contentPadding: {
    paddingBottom: '175px',
  },
  headingStyle: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-0.5px',
    lineHeight: '36px',
    marginTop: '1em',
  },
  blockStyle: {
    lineHeight: '18px',
    marginBottom: '1em',
  },
  contentStyle: {
    height: '63vh',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  mobileContentStyle: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  biggerTextStyle: {
    fontSize: 18,
    fontWeight: 700,
    marginBottom: 14,
  },
  warningIconSpacing: { marginTop: '0.5em', marginLeft: '0.5em' },
  warningIconColor: { color: '#A0001F' },
  warningTextStyle: {
    color: '#A0001F',
    fontFamily: 'DM Sans',
    fontWeight: 540,
    fontSize: '14px',
    lineHeight: '14px',
    letterSpacing: '0.1px',
  },
  smallerTextStyle: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  buttonStyle: {
    height: '18vh',
    width: '100%',
  },
  padding: {
    padding: 10,
  },
  primaryTextSTyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 500,
    letterSpacing: '0.1px',
    textTransform: 'none',
    marginBottom: 10,
  },
  secondaryTextStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textTransform: 'none',
    marginBottom: 15,
  },
  infoTextStyle: {
    fontSize: '14px',
    marginTop: '10px',
    color: '#808080',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  radioButtonStyle: {
    color: '#A0001F',
  },
  dropzoneStyle: {
    width: '100%',
    height: '127px',
    display: 'flex',
    justifyContent: 'spaceBetween',
    backgroundColor: '#A0001F1A',
    borderRadius: '12px',
    padding: '15px 30px 15px 15px',
    alignItems: 'center',
    marginTop: 20,
  },
  dropzoneStyleWithWarning: {
    width: '100%',
    height: '20vh',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#f1edf3',
    borderRadius: '12px',
    padding: '15px',
    alignItems: 'center',
    border: 'solid 1px rgb(229, 67, 0)',
  },
  mobileDropzoneStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#f1edf3',
    borderRadius: '12px',
  },
  primaryColor: {
    color: '#E4282C',
    textTransform: 'initial',
    position: 'relative',
    bottom: 5,
  },
  fileAvatarStyle: {
    color: '#A0001F',
    marginRight: 20,
  },
  fileTextStyle: {
    fontSize: '20px',
    lineHeight: '24px',
    color: '#A0001F',
  },
  fileButtonStyle: {
    color: '#A0001F',
    alignSelf: 'center',
    zIndex: 2,
  },
  buttonTextStyle: {
    color: '#A0001F',
    display: 'flex',
    fontWeight: 500,
    alignItems: 'center',
    fontSize: 16,
  },
  loadingButtonStyle: {
    color: '#A0001F',
  },
  block: {
    marginBottom: 30,
  },
  circularProgress: {
    width: '16px',
    height: '16px',
    marginRight: '6px',
    marginTop: '12px',
  },
  fileTextDescription: {
    fontSize: '14px',
    color: '#1b181c',
    marginTop: '10px',
    marginLeft: '10px',
  },
  fileTextProgress: {
    marginLeft: '20px',
    marginTop: '10px',
    color: '#A0001F',
    fontWeight: 500,
    fontSize: '14px',
  },
  infoTextStyleLoad: {
    fontSize: '14px',
    marginTop: '10px',
    color: '#808080',
  },
  inputs: {
    marginTop: 20,
    marginBottom: 30,
  },
  formControl: {
    width: '23%',
    marginRight: 18,
    backgroundColor: '#ffffff',
  },
};

export default styles;

export const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      display: 'flex',
      marginTop: '30px',
      marginLeft: '-22px',
      marginRight: '-22px',
      wordBreak: 'break-all',
    },
  }),
);
