import React, { useState, useEffect } from 'react';
import Tilt from 'react-vanilla-tilt';
import validarCpf from 'validar-cpf';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';

import {
  CircularProgress,
  FormHelperText,
  Typography,
  TextField,
} from '@material-ui/core';
import { Button } from '@material-ui/core';

import ProgressToolBar from '../../../components/ProgressToolBar';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

import insuranceRegister from '../../../lotties/insuranceRegister.json';
import formatCPF from '../../../utils/formatCPF';
import ValidadeCpf from './utils/service';
import { isMobile } from '../../../utils/breakpoints';

import styles from './styles.module.css';

function InsuranceSingleScreen() {
  const history = useHistory();
  const [cpf, setCpf] = useState('');
  const [Insurance, setInsurance] = useState({});
  const [
    openModalDismissedInsurance,
    setOpenModalDismissedInsurance,
  ] = useState(false);
  const [openModalExistingInsurance, setOpenModalExistingInsurance] = useState(
    false,
  );
  const [AlreadyRegisteredCPF, setAlreadyRegisteredCPF] = useState(false);
  const [errorCPF, setErrorCPF] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const companyCodeValueStorage = sessionStorage.getItem('currentCompanyCode');
  const companyCodeValue = companyCodeValueStorage || companies[0].code;

  const DISABLED_BUTTON = cpf?.length === 0 || isLoading;

  const animationOptions = {
    loop: false,
    autoplay: true,
    animationData: insuranceRegister,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  useEffect(() => {
    sessionStorage.removeItem('EMPLOYEE_REGISTRATION_DATA');
  });

  async function searchToken() {
    const token = await sessionStorage.getItem('@ContaSoma-Token');
    return token;
  }

  const onNextClicked = e => {
    e.preventDefault();
    const validate = validarCpf(cpf);

    validate
      ? searchToken().then(token => {
          setIsLoading(true);
          const requestOptions = {
            headers: {
              Authorization: token,
            },
          };
          ValidadeCpf.getCpf({
            cpfValue: cpf,
            companyCodeValue,
            requestOptions,
            setStates: data => {
              if (data.assured) {
                setAlreadyRegisteredCPF(true);
                setIsLoading(true);
              } else if (data.dismissed) {
                setInsurance(data.employeeCode);
                setOpenModalDismissedInsurance(true);
                setIsLoading(true);
              } else if (data.employeeCode) {
                setInsurance(data.employeeCode);
                setOpenModalExistingInsurance(true);
                setIsLoading(true);
              } else if (
                data.employeeCode === null &&
                !data.dismisse &&
                !data.linked
              ) {
                sessionStorage.setItem(
                  'useTopBar',
                  JSON.stringify({
                    name: 'Cadastro individual',
                    step: 2,
                    route: history.location.pathname,
                  }),
                );

                sessionStorage.setItem(
                  'EMPLOYEE_REGISTRATION_DATA',
                  JSON.stringify({
                    cpf,
                    employeeCode: '',
                  }),
                );

                history.push('/insurance-management/insurance-single/register');

                setIsLoading(true);
              }
              setIsLoading(false);
            },
          });
        })
      : setErrorCPF(true);
    setIsLoading(false);
  };

  const onBackClicked = () => {
    history.push('/insurance-management');
  };

  function alreadyEmployeeModal() {
    sessionStorage.setItem(
      'useTopBar',
      JSON.stringify({
        name: 'Cadastro individual',
        step: 2,
        route: history.location.pathname,
      }),
    );

    sessionStorage.setItem(
      'EMPLOYEE_REGISTRATION_DATA',
      JSON.stringify({
        cpf,
        employeeCode: Insurance,
      }),
    );

    history.push('/insurance-management/insurance-single/register');
  }

  return (
    <div className={styles.container}>
      <Typography className={styles.titleStyle}>
        Cadastro individual de segurado
      </Typography>

      <div className={styles.containerSize}>
        <div className={styles.logoArea}>
          <Tilt
            options={{ scale: 2, max: 15 }}
            className={styles.startIlustrationArea}
          >
            <Lottie options={animationOptions} height={185} width={235} />
          </Tilt>
        </div>

        <div className={styles.textContainerStyle}>
          <Typography className={styles.mediumTextStyle}>
            {isMobile
              ? 'Boas vindas!'
              : 'Boas vindas! Vamos guiá-lo durante o processo.'}
          </Typography>
          <Typography className={styles.mediumTextStyle}>
            Quando terminarmos, seu colaborador será segurado.
          </Typography>
        </div>

        <div className={styles.formContainerStyle}>
          <Typography className={styles.biggerTextStyle}>
            Para começar, qual é o CPF do segurado?
          </Typography>

          <div className={styles.row}>
            <TextField
              variant="outlined"
              value={formatCPF(cpf)}
              placeholder="CPF"
              onChange={({ target: { value } }) => setCpf(value)}
              className={styles.formTextFieldStyle}
              error={AlreadyRegisteredCPF || errorCPF}
              id="outlined-error"
              inputProps={{
                maxLength: 14,
                inputMode: 'numeric',
              }}
            />

            <Button
              type="submit"
              disabled={DISABLED_BUTTON}
              variant="outlined"
              onClick={onNextClicked}
              className={styles.continueButtonStyle}
            >
              {isLoading ? (
                <CircularProgress className={styles.circularLoadingStyle} />
              ) : (
                'Continuar'
              )}
            </Button>
          </div>

          <FormHelperText className={styles.formHelperText}>
            {(AlreadyRegisteredCPF &&
              'Este CPF já está cadastrado. Digite outro CPF.') ||
              (errorCPF && 'CPF incorreto. Por favor, digite novamente.')}
          </FormHelperText>
        </div>

        <ConfirmationDialog
          open={openModalDismissedInsurance}
          onCloseDialog={() => setOpenModalDismissedInsurance(false)}
          title="Este(a) colaborador(a) foi demitido. Deseja continuar o cadastro?"
          text="Ao continuar o cadastro, você poderá revisar as informações do(a) colaborador(a) antes de finalizar."
          ignoreText="Salvar e Solicitar Cartões"
          onConfirmClicked={alreadyEmployeeModal}
          confirmText="Continuar"
          cancelText="Cancelar"
        />

        <ConfirmationDialog
          open={openModalExistingInsurance}
          onCloseDialog={() => setOpenModalExistingInsurance(false)}
          title="Identificamos que este CPF é de um cliente FortesPay. Vamos resgatar as informações do banco de dados."
          text="No próximo passo, o formulário já estará preenchido com as informações do nosso banco de dados.
          Você poderá revisá-las antes de finalizar."
          ignoreText="Salvar e Solicitar Cartões"
          onConfirmClicked={() => {
            sessionStorage.setItem(
              'useTopBar',
              JSON.stringify({
                name: 'Cadastro individual',
                step: 2,
                route: history.location.pathname,
              }),
            );

            sessionStorage.setItem(
              'EMPLOYEE_REGISTRATION_DATA',
              JSON.stringify({
                cpf,
                employeeCode: '',
              }),
            );

            sessionStorage.setItem(
              'EMPLOYEE_REGISTRATION_DATA',
              JSON.stringify({
                cpf,
                employeeCode: Insurance,
              }),
            );

            history.push('/insurance-management/insurance-single/register');
          }}
          confirmText="Entendi"
          cancelText="Cancelar"
        />
      </div>

      <ProgressToolBar
        currentStep={0}
        numberOfSteps={0}
        onBackClicked={onBackClicked}
        backButtonText="Voltar"
        disableNext
        hidden={isMobile}
      />
    </div>
  );
}

export default InsuranceSingleScreen;
