import React from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Button, Typography } from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import ProgressToolBar from '../../../components/ProgressToolBar';
import MenuCardRegister from './UI/MenuCardRegister';
import styles from './styles.module.css';
import { isMobile } from '../../../utils/breakpoints';

import RegisterEmployeeSuccessful from './UI/RegisterEmployeeSuccessful';
import RegisterNewEmployeeSuccessful from './UI/RegisterNewEmployeeSuccessful';

export default () => {
  const history = useHistory();

  const employeeName = JSON.parse(sessionStorage.getItem('nameEmployee'));
  const uploadEmployeesConfirmationData = JSON.parse(
    sessionStorage.getItem('uploadEmployeesConfirmationData'),
  );

  const employeeNameArray = employeeName?.name.split(' ');
  const { created } = uploadEmployeesConfirmationData;

  function onNextClicked() {
    sessionStorage.removeItem('useTopBar');
    history.push('/employees');
  }

  return (
    <Box>
      {created ? (
        <RegisterEmployeeSuccessful employeeName={employeeNameArray} />
      ) : (
        <RegisterNewEmployeeSuccessful employeeName={employeeNameArray} />
      )}

      <Box className={styles.divider} />

      <Box className={styles.cardsBoxArea}>
        <Box className={styles.cardsBoxTitle}>
          <Typography className={styles.cardBoxtitleStyle}>
            Próximos passos
          </Typography>
        </Box>
        <Box className={styles.cardsBox}>
          <MenuCardRegister
            title="Agendar folha de pagamento"
            onClick={() => {
              history.push('/payroll');
            }}
            icon={<DescriptionIcon className={styles.cardsIcon} />}
          />
          <MenuCardRegister
            title="Incluir mais funcionários"
            text="Inclua mais funcionários manualmente ou utilizando uma planilha modelo da FortesPay."
            buttonText="Acessar"
            onClick={() => {
              history.push('/employees');
            }}
            icon={<PersonAddIcon className={styles.cardsIcon} />}
          />
        </Box>
      </Box>

      <Button className={styles.footerButton} onClick={onNextClicked}>
        Gerenciar funcionários
      </Button>

      <ProgressToolBar
        onNextClicked={onNextClicked}
        nextButtonText="Gerenciar funcionários"
        disableBack
        hidden={isMobile}
      />
    </Box>
  );
};
