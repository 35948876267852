import React from 'react';

import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
  TableFooter,
  Tooltip,
} from '@material-ui/core';

import { ReactComponent as DownloadIcon } from '../../../../assets/download-icon.svg';

import { api } from '../../../../services/api';
import FakeTable from './FakeTable';

import styles from './styles.module.css';

import formatCPF from '../../../../utils/formatCPF';
import textCapitalize from '../../../../utils/textCapitalize';
import convertBytesToBlobDowload from '../../../../utils/convertBytesToBlobDowload';

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell className={styles.labelHead}>CPF</TableCell>
        <TableCell className={styles.labelHead}>Nome</TableCell>
        <TableCell className={styles.labelHead}>Matrícula</TableCell>
        <TableCell className={styles.labelHead}>Ações</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  employees,
  isLoading,
  totalSize,
  pageSize,
  currentPage,
  handlePageChange,
  handleRowsPerPageChange,
  TablePaginationActions,
}) {
  function handleDownloadPdf(paycheckId) {
    api
      .get(`/paychecks/report/${paycheckId}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        const nameDocument = `contra_cheque_${paycheckId}`;
        convertBytesToBlobDowload(data, nameDocument);
      });
  }

  if (isLoading) {
    return <FakeTable />;
  }

  return (
    <Paper className={styles.tableContainer}>
      <div>
        <TableContainer>
          <Table>
            <EnhancedTableHead />

            <TableBody>
              {employees?.map(row => {
                return (
                  <TableRow hover tabIndex={-1} key={row?.cpf}>
                    <TableCell className={styles.tableCell}>
                      {formatCPF(row?.cpf)}
                    </TableCell>
                    <TableCell className={styles.tableCell}>
                      {textCapitalize(row?.employeeName)}
                    </TableCell>
                    <TableCell className={styles.tableCell}>
                      {row?.employeeRegistration}
                    </TableCell>
                    <TableCell>
                      <Tooltip arrow title="Baixar">
                        <IconButton onClick={() => handleDownloadPdf(row?.id)}>
                          <DownloadIcon className={styles.fileIcon} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  count={totalSize}
                  rowsPerPage={pageSize}
                  page={currentPage}
                  labelRowsPerPage="linhas por página"
                  labelDisplayedRows={function defaultLabelDisplayedRows({
                    from,
                    to,
                    count,
                  }) {
                    return `${from}–${to} de ${
                      count !== -1 ? count : `mais que ${to}`
                    }`;
                  }}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </Paper>
  );
}
