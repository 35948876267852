import React from 'react';
import moment from 'moment';

import { Typography } from '@material-ui/core';

import Status from './Status';
import FakeCards from './FakeCards';

import { styles } from '../styles';
import style from '../styles.module.css';

function CardsDetails({
  status,
  fileName,
  paymentDate,
  nsu,
  creationUserName,
  approvalUserName,
  paymentType,
  totalValue,
  paymentValidate,
  totalPayroll,
  fees,
  expressPayment,
  criationDate,
  criationTime,
  authorizationDate,
  authorizationTime,
  loading,
  signatureValue,
  signatureAmount,
  scheduleDate,
  expressPaymentDate,
  expressPaymentUserName,
  depositAccount,
  isMoreThanOneAccount,
}) {
  return (
    <>
      {loading ? (
        <FakeCards />
      ) : (
        <div className={style.twoCardsDetails}>
          <div className={style.cardImportDetails}>
            <div className={style.containerDetailsStatus}>
              <Typography style={styles.biggerTextStyle}>
                Detalhes da Importação
              </Typography>
              <Status status={status} />
            </div>

            <div className={style.nsuTypeName}>
              <div className={style.containerNsu}>
                <p className={style.detailTitle}>NSU</p>
                <p className={style.detailsValues}>{nsu}</p>
              </div>

              {isMoreThanOneAccount && (
                <div className={style.containerAccount}>
                  <p className={style.detailTitle}>Conta</p>
                  <p className={style.detailsValues}>{depositAccount}</p>
                </div>
              )}

              <div className={style.containerPaymentType}>
                <p className={style.detailTitle}>Tipo</p>
                <p className={style.detailsValues}>{paymentType}</p>
              </div>

              <div className={style.containerPaymentType}>
                <p className={style.detailTitle}>Agendado para</p>
                <p className={style.detailsValues}>
                  {moment(scheduleDate)?.format('DD/MM/YYYY')} às{' '}
                  {moment(scheduleDate).format('LT')}
                </p>
              </div>

              <div className={style.containerPayrollName}>
                <p className={style.detailTitle}>Arquivo</p>
                <p className={style.detailsValues}>{fileName}</p>
              </div>
            </div>

            <div className={style.containerCriationAuth}>
              <div className={style.containerCriation}>
                <p className={style.detailTitle}>Criação</p>
                <p className={style.detailsValues}>{creationUserName}</p>
                <p className={style.criationDateHour}>
                  {criationDate} às {criationTime}
                </p>
              </div>

              <div className={style.containerAuth}>
                <p className={style.detailTitle}>Autorização</p>
                <p className={style.detailsValues}>{approvalUserName}</p>
                {authorizationDate === '(Em branco)' ? (
                  <p className={style.authDateHour} />
                ) : (
                  <p className={style.authDateHour}>
                    {authorizationDate} às {authorizationTime}
                  </p>
                )}
              </div>

              {expressPaymentUserName && expressPaymentDate && (
                <div className={style.containerRelease}>
                  <p className={style.detailTitle}>Liberação imediata</p>
                  <p className={style.detailsValues}>
                    {expressPaymentUserName}
                  </p>
                  <p className={style.authDateHour}>
                    {moment(expressPaymentDate).format('DD/MM/YYYY')} às{' '}
                    {moment(expressPaymentDate).format('LT')}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className={style.cardPayment}>
            <div className={style.containerTitleDate}>
              <Typography style={styles.biggerTextStyle}>Pagamento</Typography>
              <div className={style.containerPaymentDate}>
                <p className={style.paymentDate}>
                  {paymentValidate(paymentDate)}
                </p>
              </div>
            </div>

            <div className={style.totalPayedOut}>
              <p className={style.totalAmountTitle}>Total</p>
              <p className={style.valuePayed}>{totalValue}</p>
            </div>

            <div className={style.rollFeeImediate}>
              <div className={style.rollValue}>
                <p className={style.detailTitle}>Folha</p>
                <p className={style.paymentValues}>{totalPayroll}</p>
              </div>

              <div className={style.feeAmount}>
                <p className={style.detailTitle}>Tarifa</p>
                <p className={style.paymentValues}>{fees}</p>
              </div>

              <div className={style.immediateRelease}>
                <p className={style.detailTitle}>L. Imediata</p>
                <p className={style.paymentValues}>{expressPayment}</p>
              </div>

              {signatureAmount > 0 && (
                <div className={style.plan}>
                  <p className={style.detailTitle}>Ass. Plano FortesPay</p>
                  <p className={style.paymentValues}>{signatureValue}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default CardsDetails;
