import { makeStyles } from '@material-ui/core/styles';

const styles = {
  marginStyle: {
    marginLeft: '9px',
  },
  alertStyle: {
    color: '#1A1A1A',
    fontFamily: 'DM Sans',
  },
  paidStyle: {
    color: '#1A1A1A',
    fontFamily: 'DM Sans',
  },
  confirmedStyle: {
    color: '#1A1A1A',
    fontFamily: 'DM Sans',
  },
  cancelledStyle: {
    color: '#1A1A1A',
    fontFamily: 'DM Sans',
  },
  inProcessVerificationStyle: {
    color: '#1A1A1A',
    fontFamily: 'DM Sans',
  },
  waitingStyle: {
    color: '#1A1A1A',
  },
  displayStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  iconButtonOverduePayment: {
    opacity: '.3',
    cursor: 'initial',
  },
  blueIcon: {
    color: '#2D54B9',
    width: 20,
    height: 20,
  },
  orangeIcon: {
    color: '#E54217',
    width: 20,
    height: 20,
  },
  greenIcon: {
    color: '#19797F',
    width: 20,
    height: 20,
  },
  tableHeadLabel: {
    fontSize: 14,
    fontWeight: 500,
  },
  approvedPropose: {
    color: '#1B6517',
    fontWeight: 500,
    backgroundColor: 'rgba(49, 132, 42, 0.1)',
  },
  underAnalysisRhPropose: {
    color: '#E16902',
    fontWeight: 500,
    backgroundColor: 'rgba(241, 146, 7, 0.1)',
  },
  nameAvatar: {
    color: '#A0001F',
    backgroundColor: '#A0001F20',
  },
  createdAtLabel: {
    fontSize: '0.875rem',
  },
  amountLabel: {
    fontSize: '0.875rem',
  },
  nameLabel: {
    fontSize: '0.875rem',
    fontWeight: 500,
  },
  cpfLabel: {
    fontSize: '0.875rem',
    color: '#666',
  },
  installmentLabel: {
    fontSize: '0.75rem',
    color: '#666',
  },
};

export default styles;

export const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
