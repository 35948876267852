import React from 'react';
import {
  Button,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Tooltip,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Line } from 'react-chartjs-2';

import CustomDatePicker from '../../../../../../components/DatePicker';
import NoContent from '../NoContent';
import FakePaymentsChartDashboard from './UI/FakePaymentsChartDashboard';

import { monthsAndDaysSearch } from '../../../../../../mocks/monthsAndDaysSearch';
import styles from './styles.module.css';

function MyPayments({
  activeButton,
  onButtonFilterDashboard,
  openMenuOptions,
  setOpenMenuOptions,
  openMenuEvent,
  setOpenMenuEvent,
  initialDateDash,
  finalDateDash,
  onChangeInitialDateDash,
  onChangeFinalDateDash,
  onButtonFilterMounthInDays,
  mounthPayments,
  totalPayments,
  loadingDashPayments,
  onHandleCleanFilters,
  textErrorDashPayments,
  animationChartPayment,
  setAnimationChartPayment,
  noContentPayments,
  setAnimationTopFive,
  setLastDays,
  setLastMonths,
}) {
  const data = canvas => {
    const ctx = canvas.getContext('2d');
    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, '#A0001F');
    gradient.addColorStop(0.6, 'rgba(235, 223, 235, 0.1)');

    return {
      labels: mounthPayments,
      datasets: [
        {
          fill: 'start',
          backgroundColor: gradient,
          borderColor: '#A0001F',
          borderWidth: 2,
          pointColor: '#fff',
          pointStrokeColor: '#A0001F',
          pointHighlightFill: '#fff',
          pointHighlightStroke: '#A0001F',
          data: totalPayments,
        },
      ],
    };
  };

  const options = {
    scales: {
      y: {
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    animation: animationChartPayment,
    animations: {
      tension: {
        duration: 1000,
        easing: 'linear',
        from: 1,
        to: 0,
        loop: false,
      },
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.namePayment}>
          <Typography className={styles.headerTitle}>
            Meus pagamentos
          </Typography>
        </div>
        <div className={styles.buttonsHeader}>
          {monthsAndDaysSearch.map((item, index) => {
            return (
              <Button
                disabled={loadingDashPayments}
                onClick={() => {
                  setAnimationTopFive(false);
                  onButtonFilterDashboard(item.value, index);
                }}
                className={
                  activeButton === item.value
                    ? styles.buttonFilterActive
                    : styles.buttonFilter
                }
              >
                {item.text}
              </Button>
            );
          })}

          <Button
            className={styles.buttonOptions}
            onClick={event => {
              setAnimationTopFive(false);
              setAnimationChartPayment(false);
              setOpenMenuOptions(event.currentTarget);
            }}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <IconButton className={styles.buttonIcon}>
              <MoreVertIcon className={styles.icon} />
            </IconButton>
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={openMenuOptions}
            keepMounted
            open={openMenuOptions}
            onClose={() => setOpenMenuOptions(null)}
          >
            {monthsAndDaysSearch.map((item, index) => {
              return (
                <MenuItem
                  onClick={() => onButtonFilterDashboard(item.value, index)}
                  className={styles.optionsMenuItem}
                >
                  <span>{item.text}</span>
                </MenuItem>
              );
            })}
          </Menu>
          <Tooltip title="Selecionar período">
            <Button
              className={styles.buttonEvent}
              onClick={event => {
                setAnimationChartPayment(false);
                setOpenMenuEvent(event.currentTarget);
                setLastMonths(0);
                setLastDays(0);
              }}
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              <IconButton className={styles.buttonIcon}>
                <EventIcon className={styles.icon} />
              </IconButton>
            </Button>
          </Tooltip>
          <Menu
            id="simple-menu"
            anchorEl={openMenuEvent}
            keepMounted
            open={openMenuEvent}
            onClose={() => setOpenMenuEvent(null)}
          >
            <div className={styles.content}>
              <div className={styles.containerPeriod}>
                <Typography>Período</Typography>
              </div>

              <div className={styles.containerYearAndMonth}>
                <div className={styles.containerDayDate}>
                  <CustomDatePicker
                    disableFuture
                    value={initialDateDash}
                    onChange={onChangeInitialDateDash}
                    label={initialDateDash ? 'Data inicial' : null}
                    placeholder="Data inicial"
                  />
                </div>

                <div className={styles.containerDayDate}>
                  <CustomDatePicker
                    disableFuture
                    value={finalDateDash}
                    onChange={onChangeFinalDateDash}
                    label={finalDateDash ? 'Data final' : null}
                    placeholder="Data final"
                  />
                </div>
                <div className={styles.buttonsGroup}>
                  <Button
                    onClick={onHandleCleanFilters}
                    className={styles.closeButton}
                  >
                    CANCELAR
                  </Button>

                  <Button
                    disabled={!(initialDateDash && finalDateDash)}
                    onClick={onButtonFilterMounthInDays}
                    className={styles.confirmButton}
                  >
                    APLICAR
                  </Button>
                </div>
              </div>
            </div>
          </Menu>
        </div>
      </div>

      <div className={styles.containerChart}>
        {loadingDashPayments ? (
          <FakePaymentsChartDashboard />
        ) : (
          <div className={styles.containerNoContent}>
            {noContentPayments ? (
              <NoContent
                title={
                  textErrorDashPayments ||
                  'Aqui será mostrado um gráfico com informações de pagamento.'
                }
                subTitle={
                  textErrorDashPayments &&
                  'Verifique sua conexão, aguarde alguns instantes e tente novamente mais tarde.'
                }
              />
            ) : (
              <Line
                data={data}
                options={options}
                className={styles.mobileLine}
                height={120}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default MyPayments;
