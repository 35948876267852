import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ChipStatus } from '../../../../../ChipStatus';

import styles from './styles.module.css';

export function MenuItem({
  icon = () => {},
  text = '',
  option = '',
  onClick = () => {},
  selected = false,
  disabled = false,
  isNew = false,
}) {
  return (
    <Button
      className={
        option === selected
          ? styles.menuItemButtonActive
          : styles.menuItemButton
      }
      disabled={disabled}
      onClick={onClick}
    >
      <Box className={styles.menuItemButtonContainer}>
        <Box className={styles.menuItemIcon}>{icon}</Box>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: isNew ? 'space-between' : 'start',
            paddingRight: '0.5rem',
          }}
        >
          <Typography className={styles.menuItemText}>{text}</Typography>

          {isNew && (
            <ChipStatus
              label="novo"
              size="small"
              variant="filled"
              status="error"
              classes={{
                sizeSmall: makeStyles({
                  sizeSmall: { height: 'fit-content' },
                })().sizeSmall,
                labelSmall: makeStyles({ labelSmall: { paddingX: '0.5rem' } })()
                  .labelSmall,
              }}
              chipStyle={{
                display: 'flex',
                alignItems: 'center',
                textTransform: 'uppercase',
                fontSize: '0.65rem',
                fontWeight: 'bold',
              }}
            />
          )}
        </Box>
      </Box>
    </Button>
  );
}
