import {
  createMuiTheme,
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';
import { ptBR } from '@material-ui/core/locale';

export const styles = {
  biggerTextStyle: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '27px',
    letterSpacing: '-0.5px',
    fontFamily: 'DM Sans',
    marginBottom: '15px',
  },
  paymentTypeText: {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-0.5px',
    fontFamily: 'DM Sans',
    marginBottom: '15px',
    marginTop: 20,
  },
  divider: {
    display: 'flex',
    marginTop: '30px',
    marginLeft: '-22px',
    marginRight: '-22px',
    wordBreak: 'break-all',
  },
  titleStyle: {
    fontSize: 26,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  thinTextStyle: {
    fontSize: 18,
    marginBottom: 25,
  },
  tableMargin: {
    marginTop: '4em',
  },
  backButton: {
    color: '#A0001F',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  gridStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 50,
  },
  downloadAllButton: {
    borderColor: '#E5E5E5',
    borderWidth: 1,
    borderRadius: 6,
    padding: 5,
  },
  paymentTypeAndButtonGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};

export const useStyles = makeStyles(theme =>
  createStyles({
    menuList: {
      marginTop: '48px',
      marginLeft: '-30px',
      wordBreak: 'break-all',
    },
    table: {
      maxWidth: '100%',
    },
    headTableLabel: {
      fontWeight: 500,
      fontSize: 14,
    },
    defaultCell: {
      width: '80px',
      padding: '10px 15px',
    },
    searchBackground: {
      backgroundColor: '#c1c1c1',
      marginBottom: 15,
      borderRadius: 4,
    },
    inputSearch: {
      marginBottom: '15px',
      border: 'none',
    },
    searchIcon: {
      color: '#666666',
    },
    inputs: {
      display: 'flex',
      width: '100%',
      marginTop: 5,
    },

    inputFilterNameCPF: {
      height: '56px',
      width: '70%',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
    },
    inputFilterSituation: {
      marginLeft: '10px',
      width: '30%',
      borderRadius: '6px',
      backgroundColor: '#FFFFFF',
    },
    invisible: {
      visibility: 'hidden',
      position: 'relative',
      top: 7,
      right: 10,
    },
  }),
);

export const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

export const theme = createMuiTheme(
  {
    overrides: {
      MuiTableRow: {
        root: {
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        },
      },
      MuiTableCell: {
        root: {
          fontFamily: 'DM Sans',
          fontSize: '15px',
        },
      },
      MuiTableContainer: {
        root: {
          marginTop: '15px',
        },
      },
    },
  },
  ptBR,
);
