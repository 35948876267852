import React from 'react';

import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';

import ProgressBar from '../../../../components/ProgressBar';
import Info from '../../../../components/Info';

import { ReactComponent as DescriptionIcon } from '../../../../assets/fileText.svg';

import convertBytesToBlobDownloadXls from '../../../../utils/convertBytesToBlobDownloadXls';
import { api } from '../../../../services/api';

import styles from './styles.module.css';

export default function Content({
  file,
  getRootProps,
  getInputProps,
  isDragActive,
  insuranceFileItem,
  onRemoveFileClicked,
  onCancelUpload,
  isLoading,
  insuranceData,
  IS_EXCEL_FILE,
  insuranceType,
  handleChangeInsuranceType,
  contractType,
  handleChangeContractType,
}) {
  function downloadModel() {
    const requestConfig = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
        accept: 'application/xls',
      },
      responseType: 'blob',
    };
    api
      .get('files/employees/download/model', requestConfig)
      .then(({ data }) => {
        convertBytesToBlobDownloadXls(data, 'modelo de registro de segurado');
      });
  }

  function downloadModel() {
    const requestConfig = {
      headers: {
        branding: process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION,
        accept: 'application/xls',
      },
      responseType: 'blob',
    };

    api
      .get('files/employees/download/model', requestConfig)
      .then(({ data }) => {
        convertBytesToBlobDownloadXls(data, 'modelo de registro de segurado');
      });
  }

  const renderDropzone = () => {
    const { percentage, status } = insuranceData;

    if (isLoading && percentage > 0) {
      return (
        <>
          <div className={styles.dropzone}>
            <ListItem>
              <ListItemAvatar className={styles.fileAvatarStyle}>
                <DescriptionIcon width="58" height="58" />
              </ListItemAvatar>

              <ListItemText
                primary={
                  <>
                    <Typography className={styles.fileTextStyle}>
                      Nome do arquivo
                    </Typography>

                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography
                          className={styles.fileTextDescriptionFinish}
                        >
                          Enviando arquivo (1/2)
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography className={styles.percentageText}>
                          {`${percentage} %`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                }
              />
            </ListItem>

            <Button
              variant="outlined"
              className={styles.fileButtonStyle}
              onClick={onCancelUpload}
            >
              Cancelar
            </Button>
          </div>

          <Typography className={styles.infoTextStyleLoad}>
            Enquanto verificamos o arquivo você pode continuar seu trabalho e
            acompanhar o andamento pelo indicador na parte superior.
          </Typography>
          <ProgressBar progress={percentage} />
        </>
      );
    }

    if (file.length === 0 && status !== 'PROCESSING') {
      return (
        <>
          <Button {...getRootProps()} className={styles.dropzone}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography className={styles.primaryColor}>Soltar</Typography>
            ) : (
              <Typography className={styles.buttonText}>
                <PublishIcon className={styles.buttonTextIcon} />
                Enviar um arquivo
              </Typography>
            )}
          </Button>
          <Typography className={styles.infoTextStyle}>
            Você pode arrastar ou clicar na caixa para selecionar o arquivo.
          </Typography>
        </>
      );
    }

    return (
      <>
        <div className={styles.dropzone}>
          <ListItem>
            <ListItemAvatar className={styles.fileAvatarStyle}>
              <DescriptionIcon width="58" height="58" />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography className={styles.fileTextStyle}>
                  {insuranceFileItem ? insuranceFileItem[0]?.path : ''}
                </Typography>
              }
            />
          </ListItem>
          <Button
            variant="outlined"
            className={styles.fileButtonStyle}
            onClick={onRemoveFileClicked}
            disabled={isLoading}
          >
            Remover
          </Button>
        </div>
        <Typography className={styles.infoTextStyle}>
          Enquanto verificamos o arquivo você pode continuar seu trabalho.
        </Typography>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.step}>
        <Grid container justify="space-between">
          <Grid item>
            <h3>
              {IS_EXCEL_FILE
                ? '1. Comece fazendo o download do modelo da planilha'
                : '1. Comece extraindo o arquivo do seu ERP no layout FortesPay'}
            </h3>
          </Grid>

          {IS_EXCEL_FILE && (
            <Grid item>
              <Button
                className={styles.downloadButton}
                startIcon={<GetAppRoundedIcon />}
                onClick={downloadModel}
              >
                Baixar modelo
              </Button>
            </Grid>
          )}
        </Grid>
        <Info
          icon={<ErrorOutlineRoundedIcon />}
          text={
            IS_EXCEL_FILE
              ? 'Após download, utilize o Excel ou equivalente e preencha os dados dos seus colaboradores.'
              : 'Certifique-se que o layout exportado seja o da FortesPay'
          }
          warning
        />
      </div>

      <div className={styles.step}>
        <h3>2. Configure o cadastro</h3>

        <Info
          icon={<ErrorOutlineRoundedIcon />}
          text={
            IS_EXCEL_FILE
              ? 'Com a planilha preenchida, envie o arquivo utilizando o botão abaixo.'
              : 'Importante: a configuração abaixo será aplicada em todos os cadastros contidos no arquivo'
          }
          warning
        />

        <div>
          <FormControl
            variant="outlined"
            className={styles.selectInput}
            fullWidth
          >
            <InputLabel id="insurance-type-select">
              Selecione um seguro*
            </InputLabel>
            <Select
              labelId="insurance-type-select"
              value={insuranceType}
              onChange={handleChangeInsuranceType}
              label="Selecione um seguro*"
              disabled={isLoading}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value={1}>Seguro de vida</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            variant="outlined"
            className={IS_EXCEL_FILE ? styles.hidden : styles.selectInput}
          >
            <InputLabel id="contract-type-select">Regime*</InputLabel>
            <Select
              labelId="contract-type-select"
              value={contractType}
              onChange={handleChangeContractType}
              label="Regime*"
              disabled={isLoading}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value={1}>CLT (tempo determinado)</MenuItem>
              <MenuItem value={2}>CLT (tempo Indeterminado)</MenuItem>
              <MenuItem value={3}>Autônomo (RPA)</MenuItem>
              <MenuItem value={4}>Jovem aprendiz</MenuItem>
              <MenuItem value={5}>Estágio</MenuItem>
              <MenuItem value={6}>Temporário</MenuItem>
              <MenuItem value={7}>Pro labore</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className={styles.step}>
        <h3>3. Envie o arquivo preenchido</h3>
        {renderDropzone()}
      </div>
    </div>
  );
}
