const styles = {
  iconStyle: {
    padding: 1,
    marginRight: 5,
    transition: 'all ease 0.3s',
  },
  desktopPaperStyle: {
    zIndex: 3,
    position: 'fixed',
    width: 'calc(100% - 280px)',
    bottom: 0,
    marginLeft: '-24px',
    boxShadow: '0 0 3px 0 #9E9E9E',
  },
  tollBarTablet: {
    zIndex: 3,
    position: 'fixed',
    width: '100%',
    bottom: 0,
    marginLeft: '-28px',
    boxShadow: '0 0 3px 0 #9E9E9E',
  },
  nextButtonfadedStyle: {
    backgroundColor: '#A0001F',
    color: '#ffffff',
    textTransform: 'initial',
    opacity: 0.4,
  },
  nextButtonOrange: {
    color: '#ffffff',
    backgroundColor: '#E4282C',
    textTransform: 'initial',
  },
  progressBarStyle: { flex: 8, transition: 'all ease 0.3s' },
  progressButtonsStyle: { flex: 4 },
  backButtonStyle: {
    color: '#A0001F',
    textTransform: 'initial',
  },
  nextButtonStyle: {
    color: '#ffffff',
    backgroundColor: '#A0001F',
    textTransform: 'initial',
  },
  disabledNextButtonOrange: {
    color: '#FFFFFF',
    backgroundColor: '#E4282C',
    textTransform: 'initial',
    opacity: 0.4,
  },
  textButtonNext: {
    textTransform: 'initial',
  },
  nextButtonFinalStepStyle: {
    color: '#ffffff',
    backgroundColor: '#E4282C',
    textTransform: 'initial',
  },
  hidden: {
    display: 'none',
  },
  mobileSpacing: { justifyContent: 'space-evenly' },
  loadingStyle: { color: '#A0001F' },
  count: {
    display: 'flex',
    marginLeft: 8,
    backgroundColor: '#E7E7E7',
    borderRadius: 60,
    padding: '5px 16px',
  },
  numberCount: {
    backgroundColor: '#A0001F',
    width: '28px',
    borderRadius: '50%',
    padding: '5px 10.5px',
    color: '#ffffff',
    marginRight: 9,
    fontFamily: 'DM Sans',
    display: 'flex',
    alignItens: 'center',
    justifyContent: 'center',
  },
  textCount: {
    fontFamily: 'DM Sans',
    color: '#A0001F',
    position: 'relative',
    top: 2,
  },
};

export default styles;
