import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Drawer,
  IconButton,
  CssBaseline,
  Divider,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import ScheduleModal from '../SuccessModal';
import HeaderBar from '../HeaderBar';
import Balances from '../Balances';
import LogoutModal from '../PrimaryModal';
import WithoutAccessModal from '../WithoutAccessModal';
import SwitchAccount from './UI/SwitchAccount';
import PrimaryModal from '../PrimaryModal';
import { MainMenu } from './UI/MainMenu';

import { ActionsAccount } from '../../redux/store/Accounts';
import { ActionsBalance } from '../../redux/store/Balances';
import { ActionsCompanies } from '../../redux/store/Companies';

import { api } from '../../services/api';

import currencyFormatter from '../../utils/currencyFormatter';
import { isMobile, isTablet, isDesktop } from '../../utils/breakpoints';
import { hasAuthorization, profiles } from '../../utils/hasAuthorization';

import menuOptions from '../../enums/menuOptions';

import { ReactComponent as SomapayIcon } from '../../assets/logo-fortespay.svg';
import { ReactComponent as ScheduleModalImage } from '../../assets/closeChatModal.svg';

import useStyles from './styles';

export default ({ children }) => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const getItemSelected = sessionStorage.getItem('itemSelected');
  const companyCode = sessionStorage.getItem('currentCompanyCode');
  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const currentCompanyIndex = sessionStorage.getItem('currentCompanyIndex');

  const [selectedItem, setSelectedItem] = useState(
    getItemSelected ? parseFloat(getItemSelected) : 0,
  );

  const [open, setOpen] = useState(true);
  const [openScheduleModal, setScheduleOpenModal] = useState(false);
  const [openModalLogout, setOpenModalLogout] = useState(false);
  const [openWithoutAccessModal, setOpenWithoutAccessModal] = useState(false);

  const [
    handleReleaseAnticipationScreens,
    setHandleReleaseAnticipationScreens,
  ] = useState(false);

  const payrollProduct = companies
    ? companies[currentCompanyIndex || 0]?.payrollProduct
    : '';
  const safeProduct = companies
    ? companies[currentCompanyIndex || 0]?.safeProduct
    : '';
  const consigned = companies
    ? companies[currentCompanyIndex || 0]?.consigned
    : '';
  const beevaleBenefits = companies
    ? companies[currentCompanyIndex || 0]?.beevaleBenefits
    : '';

  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));
  const WITHOUT_ACCESS_PROFILE = 0;

  const {
    accounts,
    accountSelectedToConfirm,
    accountIndexSelectedToConfirm,
    accountSelected,
    isAccountLoading,
    isOpenModalToSwitchAccount,
  } = useSelector(state => state.account);

  const { balances, isShowBalance, isLoadingBalance } = useSelector(
    state => state.balance,
  );

  const { currentCompany } = useSelector(state => state.companies);

  const PERMISSION_TO_VIEW_BALANCE = hasAuthorization(ACCESS_PROFILES, [
    profiles.ADMIN,
    profiles.FINANCIAL,
    profiles.AUTHORIZER,
  ]);

  const getCompanyData = () => {
    api
      .get(`/companies/${companyCode}/search`)
      .then(({ data }) => {
        dispatch(
          ActionsAccount.setUpdatableIncome(data?.hasToUpdateIncomeInfo),
        );
        dispatch(ActionsCompanies.setCompanyPixKey(data?.companyPixKey));
        setHandleReleaseAnticipationScreens(data?.salaryAdvance);
      })
      .catch(error => {})
      .finally(() => {});
  };

  const handleConfirmAccountSelected = () => {
    dispatch(ActionsAccount.setAccountSelected(accountSelectedToConfirm));
    dispatch(
      ActionsAccount.setAccountIndexSelected(accountIndexSelectedToConfirm),
    );
    handleCloseConfirmChangeAccountModal();
  };

  const handleCloseConfirmChangeAccountModal = () => {
    dispatch(ActionsAccount.setAccountSelectedToConfirm({}));
    dispatch(ActionsAccount.setIsOpenModalToSwitchAccount(false));
  };

  const handleProductAuthorization = () => {
    if (
      (history.location.pathname.substring(0, 8) === '/payroll' ||
        history.location.pathname.substring(0, 10) === '/employees') &&
      !payrollProduct
    ) {
      return history.push('/');
    }

    if (
      history.location.pathname.substring(0, 10) === '/insurance' &&
      !safeProduct
    ) {
      return history.push('/');
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch({ type: 'SET_DRAWER', drawer: true });
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch({ type: 'SET_DRAWER', drawer: false });
  };

  const systemLogout = () => {
    history.push('/logout');
  };

  const confirmLogout = () => {
    setOpenModalLogout(true);
  };

  const handleClickSomapayLogo = () => {
    history.push('/');
  };

  useEffect(() => {
    history.listen(location => {
      switch (location.pathname) {
        case '/':
          setSelectedItem(0);
          break;
        case '/cash-in':
          setSelectedItem(1.1);
          break;
        case '/cash-in/inform-transfer-Deposit':
          setSelectedItem(1.1);
          break;
        case '/cash-in/new/billing':
          setSelectedItem(1.1);
          break;
        case '/payroll':
          setSelectedItem(1.2);
          break;
        case '/payroll/manual-payroll':
          setSelectedItem(1.2);
          break;
        case '/payroll/menu':
          setSelectedItem(1.2);
          break;
        case '/payroll/menu/info-payroll/register-batch':
          setSelectedItem(1.2);
          break;
        case '/payroll/menu/info-payroll/register-batch/data-review':
          setSelectedItem(1.2);
          break;
        case '/payroll/menu/info-payroll/register-batch/list-error':
          setSelectedItem(1.2);
          break;
        case '/payroll/file-batch/upload':
          setSelectedItem(1.2);
          break;
        case '/payroll/file-batch/confirm':
          setSelectedItem(1.2);
          break;

        case '/extract':
          setSelectedItem(1.3);
          break;
        case '/manage-registered-accounts':
          setSelectedItem(1.4);
          break;
        case '/cash-in/pix':
          setSelectedItem(1.5);
          break;
        case '/consolidated-values':
          setSelectedItem(1.6);
          break;
        case '/cash-out/manage':
          setSelectedItem(1.7);
          break;
        case '/cash-out/pix':
          setSelectedItem(1.7);
          break;
        case '/cash-out/transfer':
          setSelectedItem(1.7);
          break;
        case '/cash-out/bill':
          setSelectedItem(1.7);
          break;
        case '/employees/register-single':
          setSelectedItem(2.1);
          break;
        case '/employees/register-batch/menu':
          setSelectedItem(2.1);
          break;
        case '/employees/register-batch/menu/select-company/excel-form':
          setSelectedItem(2.1);
          break;
        case '/employees/register-batch/menu/select-company/excel-form/employee-list':
          setSelectedItem(2.1);
          break;
        case '/paycheck':
          setSelectedItem(2.2);
          break;
        case '/anticipation-values':
          setSelectedItem(2.3);
          break;
        case '/limit-query':
          setSelectedItem(2.4);
          break;
        case '/cost-and-sector-center':
          setSelectedItem(3.1);
          break;
        case '/my-plan':
          setSelectedItem(3.3);
          break;
        case '/somapay-academy':
          setSelectedItem(3.4);
          break;
        case '/access-settings':
          setSelectedItem(3.4);
          break;
        case '/insurance-management':
          setSelectedItem(4);
          break;
        case '/online-point-employees':
          setSelectedItem(5.1);
          break;
        case '/online-point-plan':
          setSelectedItem(5.2);
          break;
        case '/help-center':
          setSelectedItem(10);
          break;
        // no default
      }
    });

    sessionStorage.setItem('itemSelected', selectedItem);

    handleProductAuthorization();
  }, [selectedItem]);

  useEffect(() => {
    dispatch(ActionsAccount.getAccounts());

    return () => {
      dispatch(ActionsAccount.cleanup());
    };
  }, [currentCompany]);

  useEffect(() => {
    const firstAccountToSelect = 'FACIL_SCD';

    if (accounts?.length > 0) {
      const account = accounts.find(
        account => account?.origin === firstAccountToSelect,
      );
      const accountToDispatch = account || accounts[0];

      dispatch(ActionsAccount.setAccountSelected(accountToDispatch));
    } else {
      dispatch(ActionsAccount.setAccountSelected({}));
    }
  }, [currentCompany, accounts]);

  useEffect(() => {
    if (isShowBalance && accountSelected?.encodedKey) {
      dispatch(ActionsBalance.getBalances());
    }

    return () => {
      dispatch(ActionsBalance.cleanup());
    };
  }, [isShowBalance, accountSelected?.encodedKey]);

  useEffect(() => {
    if (isMobile || isTablet) {
      setOpen(false);
      dispatch({ type: 'SET_DRAWER', drawer: false });
    }

    if (ACCESS_PROFILES?.length === WITHOUT_ACCESS_PROFILE) {
      setOpenWithoutAccessModal(true);
      return;
    }

    setOpenWithoutAccessModal(false);
  }, [companyCode]);

  useEffect(() => {
    getCompanyData();
  }, [companyCode]);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <HeaderBar open={open} handleDrawerOpen={handleDrawerOpen} />

      <Drawer
        className={classes.drawer}
        variant={isMobile ? 'temporary' : 'persistent'}
        onClose={handleDrawerClose}
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeaderLogo}>
          <SomapayIcon
            onClick={handleClickSomapayLogo}
            className={classes.somapayIcon}
          />
          <IconButton
            onClick={handleDrawerClose}
            style={isDesktop ? { display: 'none' } : {}}
          >
            {theme.direction === 'ltr' ? (
              <MenuOpenIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>

        <div>
          <Box mt={PERMISSION_TO_VIEW_BALANCE ? 10 : 6.2} />

          <SwitchAccount
            label="Conta"
            content={accounts}
            loading={isAccountLoading}
            accountSelected={accountSelected}
          />

          {PERMISSION_TO_VIEW_BALANCE && (
            <Balances
              label="Saldo Disponível"
              value={
                balances?.companyBalance
                  ? currencyFormatter(balances?.companyBalance)
                  : 'R$ 0,00'
              }
              alternativeLabel="Saldo Reservado"
              alternativeValue={
                balances?.companyHoldBalance
                  ? currencyFormatter(balances?.companyHoldBalance)
                  : 'R$ 0,00'
              }
              onClick={() =>
                dispatch(ActionsBalance.setIsShowBalance(!isShowBalance))
              }
              isVisible={isShowBalance}
              isLoading={isLoadingBalance}
            />
          )}
        </div>

        <Divider className={classes.dividerMenu} />

        <MainMenu
          payrollProduct={payrollProduct}
          handleReleaseAnticipationScreens={handleReleaseAnticipationScreens}
          onConfirmExitButtonClicked={confirmLogout}
          consigned={consigned}
          beevaleBenefits={beevaleBenefits}
        />

        <LogoutModal
          title="Desejar realmente sair?"
          text="Antes de sair, verifique se todas as suas solicitações foram concluídas."
          confirmButtonText="Sair"
          onConfirmClicked={systemLogout}
          cancelButtonText="Cancelar"
          open={openModalLogout}
          onCancelButtonClicked={() => setOpenModalLogout(false)}
        />
      </Drawer>
      <main
        className={
          isMobile
            ? clsx(classes.contentShift)
            : clsx(classes.content, {
                [classes.contentShift]: open,
              })
        }
      >
        <div className={isMobile && classes.mobilePadding}>{children}</div>
      </main>

      <PrimaryModal
        open={isOpenModalToSwitchAccount}
        onCloseDialog={() => handleCloseConfirmChangeAccountModal()}
        title="Deseja trocar de conta?"
        purpleButton
        confirmButtonText="Sim"
        cancelButtonText="Não"
        onConfirmClicked={() => handleConfirmAccountSelected()}
        onCancelButtonClicked={() => handleCloseConfirmChangeAccountModal()}
        loading={isAccountLoading}
      />

      <ScheduleModal
        title="Desculpe! Estamos fora do nosso horário de atendimento"
        text="Disponíveis de Segunda a Sábado das 8:00 às 20:00 horas."
        closeButtonText="Fechar"
        image={<ScheduleModalImage />}
        open={openScheduleModal}
        onCloseDialog={() => setScheduleOpenModal(false)}
      />

      <WithoutAccessModal
        open={openWithoutAccessModal}
        title="Acesso bloqueado"
        text={
          <span>
            Seu perfil de acesso não tem autorização para acessar as
            funcionalidade e informações dessa empresa.
            <br />
            <br />
            Entre em contato com o usuário administrador e solicite a liberação
            do acesso. Caso tenha acesso para outra empresa, selecione ela na
            lista conforme exemplo.
          </span>
        }
      />
    </div>
  );
};
