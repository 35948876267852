import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Button,
  FormControlLabel,
  InputAdornment,
  TextField,
  IconButton
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

import PurpleRadio from '../../../../../components/RadioButton/PurpleRadio';

import styles from './styles.module.css';

function Filters({ clearSelectedInsurances, setOpenDrawer }) {
  const dispatch = useDispatch();

  const [cpfOrName, setCpfOrName] = useState('');
  const insuranceSituation = sessionStorage.getItem('insuranceSituation');
  const insuranceTypeStorage = sessionStorage.getItem('insuranceType');
  const [selectedValue, setSelectedValue] = useState(
    insuranceSituation ? insuranceSituation : 'Vigente'
  );
  const [insuranceTypeSelected, setInsuranceTypeSelected] = useState(
    insuranceTypeStorage ? insuranceTypeStorage : 'Seguro de Vida'
  );
  const [textFieldFocused, setTextFieldFocused] = useState('');

  useEffect(() => {
    dispatch({
      type: 'SET_INSURANCE_SITUATION',
      insuranceSituation: selectedValue,
    });

    sessionStorage.setItem('insuranceSituation', selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    dispatch({
      type: 'SET_INSURANCE_TYPE',
      insuranceType: insuranceTypeSelected,
    });

    sessionStorage.setItem('insuranceType', insuranceTypeSelected);
  }, [insuranceTypeSelected]);

  useEffect(() => {
    dispatch({
      type: 'SET_CPF_OR_NAME',
      cpfOrName: cpfOrName,
    });
  }, [cpfOrName]);

  function handleChangeInsuranceSituation(event) {
    setSelectedValue(event.target.value);
    clearSelectedInsurances();
  };

  function handleChangeCpfOrName(event) {
    setCpfOrName(event.target.value);
  };

  return (
    <div className={styles.container}>
      <TextField
        value={cpfOrName}
        onChange={handleChangeCpfOrName}
        className={styles.searchInput}
        variant="outlined"
        placeholder="Pesquise por nome ou CPF"
        onFocus={() => setTimeout(() => setTextFieldFocused('cpfOrName'), 200)}
        onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search className={styles.iconColor} />
            </InputAdornment>
          ),
          endAdornment: (cpfOrName?.length > 0 && textFieldFocused === 'cpfOrName') && (
            <IconButton
              className={styles.endAdornment}
              onClick={() => setCpfOrName('')}
            >
              <CloseRoundedIcon className={styles.endAdornmentIcon} />
            </IconButton>
          )
        }}
      />

      <div className={styles.chipsContainer}>
        <Button
          className={styles.chip}
          onClick={() => setOpenDrawer(true)}
        >
          {insuranceTypeStorage || 'Seguro de Vida'}
          <EditRoundedIcon className={styles.chipIcon} />
        </Button>

        <Button
          className={styles.chip}
          onClick={() => setOpenDrawer(true)}
        >
          {insuranceSituation || 'Vigente'}
          <EditRoundedIcon className={styles.chipIcon} />
        </Button>
      </div>

      <div className={styles.radioContainer}>
        <FormControlLabel
          className={styles.radioButton}
          value="Vigente"
          control={
            <PurpleRadio
              checked={selectedValue === 'Vigente'}
              onChange={handleChangeInsuranceSituation}
              value="Vigente"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'Vigente' }}
              color="#A0001F"
            />
          }
          label="Vigentes"
        />

        <FormControlLabel
          className={styles.radioButton}
          value="Cancelado"
          control={
            <PurpleRadio
              checked={selectedValue === 'Cancelado'}
              onChange={handleChangeInsuranceSituation}
              value="Cancelado"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'Cancelado' }}
            />
          }
          label="Cancelados"
        />

      </div>
    </div>
  );
}

export default Filters;
