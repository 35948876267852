import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const OrangeCheckbox = withStyles({
  root: {
    color: '#545255',
    '&$checked': {
      color: '#A0001F',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

export default OrangeCheckbox;
