/* eslint-disable no-useless-escape */

import * as yup from 'yup';

export default yup.object().shape({
  password: yup
    .string()
    .min(8, 'Deve ser maior que 8 caracteres')
    .required('O campo é obrigatório'),
  repeatPassword: yup
    .string()
    .min(8, 'Deve ser maior que 8 caracteres')
    .oneOf([yup.ref('password'), null], 'As senhas não correspondem')
    .required('O campo é obrigatório'),
  length: yup
    .string()
    .matches(/^(?=.{8,})/, 'error')
    .required(),
  uppercase: yup
    .string()
    .matches(/^(?=.*[A-Z])/, 'error')
    .required(),
  number: yup
    .string()
    .matches(/^(?=.*[0-9])/, 'error')
    .required(),
  special: yup
    .string()
    .matches(/^(?=.*[!@#\$%\^&\*])/, 'error')
    .required(),
});
