import React from 'react';
import {
  Accordion,
  AccordionSummary,
  Box,
  Typography,
} from '@material-ui/core';

import { ReactComponent as ExpandedArrow } from '../../../../../../assets/Icons/menu/keyboard_arrow_down.svg';

import { DotStatus } from '../../../../../DotStatus';

import styles from './styles.module.css';

export function MenuAccordion({
  accordionId,
  summaryIcon,
  summaryText,
  isNew = false,
  expanded,
  handleExpanded,
  children,
}) {
  return (
    <Accordion
      expanded={expanded}
      onChange={() => handleExpanded(accordionId)}
      className={styles.menuAccordion}
    >
      <AccordionSummary
        id="panel1a-content"
        aria-controls="panel1a-content"
        expandIcon={
          <ExpandedArrow
            className={expanded && styles.menuAccordionSummaryExpandIconActive}
          />
        }
        className={
          expanded
            ? styles.menuAccordionSummaryActive
            : styles.menuAccordionSummary
        }
      >
        <Box className={styles.menuAccordionSummaryContainer}>
          <Box className={styles.menuAccordionSummaryIcon}>{summaryIcon}</Box>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: isNew ? 'space-between' : 'start',
            }}
          >
            <Typography className={styles.menuAccordionSummaryText}>
              {summaryText}
            </Typography>

            {isNew && <DotStatus status="error" />}
          </Box>
        </Box>
      </AccordionSummary>

      <Box className={styles.menuAccordionContainer}>{children}</Box>
    </Accordion>
  );
}
