import React from 'react';
import {
  Dialog,
} from '@material-ui/core';
import styles from './styles.module.css';

function HirePlanModal({
  open,
  onCloseDialog,
  onHireClicked,
}) {
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      fullWidth
      maxWidth='xs'
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <p>Plano a definir</p>
          <span>Você não possui nenhum plano contratado.</span>
        </div>

        <div className={styles.hireContainer}>
          <div className={styles.textContainer}>
            <p>
              <strong>Contrate um plano</strong> e tenha acesso total a Plataforma FortesPay empresas
            </p>
          </div>

          {/* <Button
            className={styles.hireButton}
            onClick={onHireClicked}
          >
            Contratar
          </Button> */}
        </div>

      </div>
    </Dialog>
  );
}

export default HirePlanModal;
